import "./App.css";
import "./styles/globals.css";
import { BrowserRouter, Route } from "react-router-dom";
import {
  DetailPost,
  FormStarTrek,
  Home,
  MyPost,
  PostList,
  Rule,
} from "./pages";

function App() {
  return (
    <BrowserRouter>
      {/* <Route exact path="/form-startrek" component={FormStarTrek} /> */}

      {/* <Route exact path="/post-list" component={PostList} /> */}

      <Route exact path="/my-post" component={MyPost} />

      {/* <Route exact path="/rule" component={Rule} /> */}

      <Route exact path="/detail-post=:id?" component={DetailPost} />

      <Route exact path="/" component={Home} />
    </BrowserRouter>
  );
}

export default App;

import { makeAutoObservable } from "mobx";
import {
  IPostFeatureResponse,
  loadFeaturePostsAPI,
} from "../apis/post/load-feature-posts.api";

class PostStore {
  featurePost: IPostFeatureResponse[] = [];
  loadingFeaturePosts: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadFeaturePosts = async () => {
    this.loadingFeaturePosts = true;

    try {
      const { data } = await loadFeaturePostsAPI(10);

      this.featurePost = data.sort((postPre, postNext) => {
        const dateInNumberPrev = new Date(postPre.createDate || "").getTime();
        const dateInNumberNext = new Date(postNext.createDate || "").getTime();

        return dateInNumberNext - dateInNumberPrev;
      });

      this.loadingFeaturePosts = false;
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingFeaturePosts = false;
    }
  };
}

const postStore = new PostStore();

export default postStore;

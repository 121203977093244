// PostStore.ts
import { makeAutoObservable } from "mobx";
import { IPostFeatureResponse } from "../apis/post/load-feature-posts.api";
import { loadNewPostsAPI, loadTopPostsAPI } from "../apis";
import popUpStore, { EPopUp } from "./popup.store";

class PostListStore {
  selectedTab: "top-vote" | "new-post" = "top-vote";
  loading: boolean = false;
  displayNewPost: IPostFeatureResponse[] | undefined = undefined;
  displayTopPost: IPostFeatureResponse[] | undefined = undefined;
  pageNewPost: number = 1;
  pageTopPost: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  async loadNewPosts(page: number) {
    try {
      this.loading = true;

      const { data } = await loadNewPostsAPI(page);
      if (this.selectedTab === "new-post" && this.pageNewPost === 1) {
        // If the selected tab is "new-post", update the displayNewPost
        this.displayNewPost = [];
        data.map((post) => this.displayNewPost?.push(post));
      }

      this.loading = false;
    } catch (error) {
      console.error("Error loading new posts:", error);
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
      this.loading = false;
    }
  }

  async loadTopPosts(page: number) {
    try {
      this.loading = true;

      const { data } = await loadTopPostsAPI(page);
      if (this.selectedTab === "top-vote" && this.pageTopPost === 1) {
        // If the selected tab is "top-vote", update the displayTopPost
        this.displayTopPost = [];
        data.map((post) => this.displayTopPost?.push(post));
      }

      this.loading = false;
    } catch (error) {
      console.error("Error loading top posts:", error);
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
      this.loading = false;
    }
  }

  setPageNewPost(page: number) {
    this.pageNewPost = page;
  }

  setPageTopPost(page: number) {
    this.pageTopPost = page;
  }

  setTab(tab: "top-vote" | "new-post") {
    this.selectedTab = tab;
  }
}

const postListStore = new PostListStore();
export default postListStore;

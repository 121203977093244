import { makeAutoObservable, reaction } from "mobx";
import { sentContactAPI } from "../apis/contact";
import authenticationStore from "./authentication.store";
import userStore from "./user.store";

class ContactFormStore {
  name = "";
  phoneOrEmail = "";
  message = "";
  userThirdPartyID: undefined | string =
    authenticationStore.profileLogin?.thirdPartyId ||
    userStore.userProfile?.thirdPartyId ||
    undefined;
  loading: boolean = false;
  error: undefined | string = undefined;
  sentSuccess: boolean = false;

  // Regular expression to detect script tags
  scriptTagPattern = /(<[^>]+>)|(https?:\/\/)/gi;

  constructor() {
    makeAutoObservable(this);

    // Set up reaction to update userThirdPartyID when dependencies change
    reaction(
      () => [
        authenticationStore.profileLogin?.thirdPartyId,
        userStore.userProfile?.thirdPartyId,
      ],
      ([thirdPartyIdFromAuth, thirdPartyIdFromUser]) => {
        this.userThirdPartyID =
          thirdPartyIdFromAuth || thirdPartyIdFromUser || undefined;
      }
    );
  }

  validateInput(input: string): boolean {
    return !this.scriptTagPattern.test(input);
  }

  setName(name: string) {
    if (this.validateInput(name)) {
      this.name = name;
    } else {
      this.setError("Name contains invalid content.");
    }
  }

  setPhoneOrEmail(phoneOrEmail: string) {
    if (this.validateInput(phoneOrEmail)) {
      this.phoneOrEmail = phoneOrEmail;
    } else {
      this.setError("Phone or Email contains invalid content.");
    }
  }

  setMessage(message: string) {
    if (this.validateInput(message)) {
      this.message = message;
    } else {
      this.setError("Message contains invalid content.");
    }
  }

  setUserID(userThirdPartyID: string) {
    this.userThirdPartyID = userThirdPartyID;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setError(error: string | undefined) {
    this.error = error;
  }

  async submitForm() {
    this.setError(undefined);

    if (this.name === "" || this.phoneOrEmail === "" || this.message === "") {
      return this.setError(
        "Bạn vui lòng điền đủ các thông tin trên để BTC có thể hỗ trợ!"
      );
    }

    this.setLoading(true);

    try {
      const { data } = await sentContactAPI(
        this.name,
        this.phoneOrEmail,
        this.message,
        this.userThirdPartyID
      );
      // Clear form fields on successful submission

      this.setName("");
      this.setPhoneOrEmail("");
      this.setMessage("");
      this.setUserID("");

      if (data) {
        this.sentSuccess = true;
      }
    } catch (error) {
      this.setError("Xin lỗi, có lỗi xảy ra, vui lòng thử lại sau!");
    } finally {
      this.setLoading(false);
    }
  }
}

const contactFormStore = new ContactFormStore();

export default contactFormStore;

import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import detailStore from "../../stores/detail-post.store";
import { CarouselPromotion, Footer, Loading, NavBar } from "../../views";
import Avatar from "react-avatar";
import { FaHeart } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import authenticationStore from "../../stores/authentication.store";
import userStore from "../../stores/user.store";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import voteStore from "../../stores/vote.store";
import { PopupModal } from "../../views/popup-modal/popup-modal.view";
import "./detail-post.css";
import billboardNight from "../../assets/home/billboard_holder_night.jpg";
import endFormPC from "../../assets/form/CTA_END_PC.png";

interface IDetailPostDesktopProps {}

export function formatDate(timestamp: string): string {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const DetailPostDesktop: React.FC<IDetailPostDesktopProps> = observer(
  (props) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const isVoted: boolean | undefined = useMemo(() => {
      if (!authenticationStore.profileLogin && !userStore.userProfile)
        return false;

      return detailStore.detailPost?.votes
        .map((item) => item.thirdPartyId)
        .includes(
          authenticationStore.profileLogin?.thirdPartyId ||
            userStore.userProfile?.thirdPartyId ||
            ""
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      authenticationStore.profileLogin,
      userStore.userProfile,
      detailStore.detailPost,
    ]);

    useEffect(() => {
      detailStore.loadDetailPost(id);
    }, [id]);

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!detailStore.detailPost?.postID) return;
      const parsedHTML = document
        .createRange()
        .createContextualFragment(
          `<div class="zalo-share-button" data-href="https://traveloka.link/e/${detailStore.detailPost?.postID}" data-oaid="4498034832828454541" data-layout="1" data-color="blue" data-share-type="3" data-customize="true"></div><script src="https://sp.zalo.me/plugins/sdk.js"></script>`
        );
      divRef.current?.appendChild(parsedHTML);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailStore.detailPost?.postID]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (detailStore.detailPost?.postStatus === "PENDING") {
        popUpStore.openModal(EPopUp.PENDING_POST);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailStore.detailPost?.postStatus]);

    return (
      <div className="relative">
        {(detailStore.loading || voteStore.loadingVote) && <Loading />}

        <NavBar />

        {detailStore.error && <p className="mt-24">{detailStore.error}</p>}

        {detailStore.detailPost && (
          <>
            <div className="px-60 flex flex-wrap flex-col w-full mt-20 bg-white pb-20">
              <div className="flex flex-wrap justify-start pl-10 xl:pl-24 items-center w-full gap-6 mt-4 pb-3">
                <Avatar
                  src={process.env.REACT_APP_API_ENDPOINT?.replace(
                    "/api",
                    detailStore.detailPost.avatar
                  )}
                  alt={detailStore.detailPost.name}
                  size="44"
                  round
                  className="border-[0.7px] border-[#079AF2]"
                />
                <div className="flex flex-col h-full justify-between items-start flex-wrap py-1">
                  <p className="font-bold">{detailStore.detailPost.name}</p>
                  <p className="text-sky-700">
                    {formatDate(detailStore.detailPost.createDate)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col flex-wrap w-full gap-0 relative">
                <div className="relative">
                  {/* Image */}
                  <div className="w-[996px] h-[560px] mx-auto relative">
                    <img
                      className="w-full h-full"
                      src={`${process.env.REACT_APP_CDN_URL}${billboardNight}`}
                      alt="preview"
                    />
                    <div className="absolute top-[55px] left-[116px]">
                      <div className="flex flex-col flew-wrap gap-0 md:w-[769px]">
                        <div className="w-full">
                          <img
                            className={`w-full ${detailStore.detailPost.postStatus === "PENDING" ? "blur-[4px]" : ""}`}
                            src={`https:${detailStore.detailPost.imagePost}`}
                            alt="Startrek"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="relative flex flex-wrap flex-col justify-center items-center gap-1.5 py-3 bg-white"
                  onClick={() => {
                    // if (isVoted) return;
                    // if (
                    //   !authenticationStore.profileLogin &&
                    //   !userStore.userProfile
                    // ) {
                    //   return popUpStore.openModal(EPopUp.LOGIN_JOURNEY2);
                    // } else {
                    //   return voteStore.voteWithAuth(
                    //     detailStore.detailPost?.postID || ""
                    //   );
                    // }
                  }}
                >
                  <div className="relative flex flex-col gap-2 flex-wrap justify-center items-center">
                    <FaHeart color="#FF7902" size={60} />
                    <p className="absolute top-[50%] left-[50%] transform -translate-y-1/2 -translate-x-1/2 text-white text-base">
                      {detailStore.detailPost.voteCount}
                    </p>
                  </div>
                  <p className="text-center font-bold text-base text-[#464646]">
                    Thả tim
                  </p>
                </div>
              </div>

              <div className="mx-auto text-center text-base text-[#464646] px-8">
                Thả tim ngay để giúp {detailStore.detailPost.name} giật voucher
                khủng, chiếm sóng trên billboard/LED
              </div>

              <div className="w-full px-4">
                {/* <button
                className={`rounded-[99px] mt-3 py-2.5 px-3 bg-[#FF7902] text-white font-bold w-full ${
                  isVoted && "opacity-50"
                }`}
                onClick={() => {
                  if (
                    !authenticationStore.profileLogin &&
                    !userStore.userProfile
                  ) {
                    return popUpStore.openModal(EPopUp.LOGIN_JOURNEY2);
                  } else {
                    return voteStore.voteWithAuth(
                      detailStore.detailPost?.postID || ""
                    );
                  }
                }}
                disabled={isVoted}
              >
                Thả tim cho bài dự thi ngay thôi
              </button> */}
                <p
                  className={`${isVoted ? "block" : "hidden"} text-red-300 text-center text-base mt-2 px-4`}
                >
                  Bạn đã bình chọn cho bài viết này, hãy bình chọn cho bài viết
                  khác nhé!
                </p>
              </div>

              <div className=" flex flex-wrap justify-between items-center w-10/12 mx-auto px-4 py-4">
                <div
                  className="rounded-[99px] h-10 text-base flex justify-center items-center w-[48%] text-center bg-[#2196F3] text-white font-bold relative"
                  ref={divRef}
                >
                  Chia sẻ Zalo
                </div>

                <div
                  className="rounded-[99px] h-10 flex justify-center items-center w-[48%] text-center bg-[#0866FF] text-white font-bold"
                  data-href="https://startrek.traveloka.link/"
                  data-layout="button_count"
                  data-size="small"
                >
                  <a
                    className="w-full fb-xfbml-parse-ignore text-base"
                    rel="noreferrer"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftraveloka.link/e/${detailStore.detailPost.postID}&src=sdkprepars&hashtag=%23DiDuLichDatTraveloka`}
                  >
                    Chia sẻ Facebook
                  </a>
                </div>
              </div>

              <p className="text-center px-8 text-base text-[#464646]">
                Sao chép link để chia sẻ bạn bè
              </p>

              <div className="relative flex flex-wrap items-center w-10/12 mx-auto px-4 mt-2">
                <input
                  className="py-2.5 px-3 text-base bg-gray-300 text-[#005498] rounded-[99px] w-full"
                  disabled
                  defaultValue={`https://traveloka.link/e/${detailStore.detailPost.postID}`}
                />
                <button
                  className="rounded-[99px] text-base py-2.5 px-6 bg-sky-500 text-white font-bold absolute right-4 top-0"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://traveloka.link/e/${detailStore.detailPost?.postID}` ||
                        ""
                    );

                    withReactContent(Swal).fire({
                      position: "center",
                      title:
                        '<h5 style="font-size: 10px; color: #005498">Copied Clipboard</h5>',
                      width: 150,
                      color: "var(--secondary)",
                      showConfirmButton: false,
                      timer: 800,
                    });
                  }}
                >
                  Copy Link
                </button>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col flex-wrap w-full gap-1 relative bg-white pt-4 -translate-y-[40px]">
          <div className="flex flex-col flew-wrap gap-0">
            <div className="w-full">
              <img
                className="w-full"
                src={`${process.env.REACT_APP_CDN_URL}${endFormPC}`}
                alt="Startrek"
              />
            </div>
          </div>
          <div className="absolute bottom-16 left-[50%] -translate-x-1/2 px-20 w-4/12 mx-auto flex flex-wrap justify-center flex-col gap-3">
            <button
              className="bg-[#FF7902] px-8 py-1 rounded-[99px] text-white text-sm"
              onClick={() => {
                // if (authenticationStore.accessToken) {
                //   history.push("/form-startrek");
                // } else {
                //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                // }
              }}
            >
              Nhả chữ ngay
            </button>
          </div>
        </div>

        <CarouselPromotion isDesktop />

        {/* <div className="w-full h-[4rem] bg-[#0180DF]" /> */}

        {/* <FeaturePost /> */}

        <PopupModal />

        <Footer />
      </div>
    );
  }
);

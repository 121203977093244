// stores/changeProfile.store.ts
import { makeAutoObservable } from "mobx";
import authenticationStore from "./authentication.store";
import userStore from "./user.store";

class ChangeProfileStore {
  name: string =
    authenticationStore.profileLogin?.name || userStore.userProfile?.name || "";
  email: string = this.initializeEmail();
  phoneNumber: string =
    userStore.userProfile?.phoneNumber !== "_"
      ? userStore.userProfile?.phoneNumber || ""
      : "";
  selectedFile: File | null = null;
  croppedImage: string | null = null;
  isIgnoreAvatarGen: boolean =
    authenticationStore.profileLogin?.isIgnoreAvatarGen ||
    userStore.userProfile?.isIgnoreAvatarGen ||
    false;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  initializeEmail(): string {
    const emailFromAuth = authenticationStore.profileLogin?.email;
    const emailFromUser = userStore.userProfile?.email;
    if (emailFromAuth === "_" || emailFromUser === "_") {
      return "";
    }
    return emailFromAuth || emailFromUser || "";
  }

  setName(name: string) {
    this.name = name;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  setSelectedFile(file: File | null) {
    this.selectedFile = file;
  }

  setCroppedImage(image: string | null) {
    this.croppedImage = image;
  }

  setIsIgnoreAvatarGen(isIgnore: boolean) {
    this.isIgnoreAvatarGen = isIgnore;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }
}

const changeProfileStore = new ChangeProfileStore();

export default changeProfileStore;

import { API } from "../api";
import { ISignInAndVoteResponse } from "./signin-and-vote-facebook.api";
import { IVoteWithAutheResponse } from "./vote-with-authen.api";

export const signinAndVoteZaloAPI = (postId: string, zaloCode: string) => {
  const formData = new URLSearchParams();
  formData.append("zaloCode", zaloCode);
  formData.append("postId", postId);

  return API.post<ISignInAndVoteResponse | IVoteWithAutheResponse | any>(
    "/vote/signin-and-vote-zalo",
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "clean-request": "no-clean",
      },
    }
  );
};

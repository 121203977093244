// CoreAuthenticationStore.js
import { observable, action, makeObservable } from "mobx";
import { setApiAccessToken, signinFacebookAPI, signinZaloAPI } from "../apis";
import { ISignInResponseData } from "../models";
import popUpStore, { EPopUp } from "./popup.store";
import userStore from "./user.store";

class AuthenticationStore {
  accessToken: string | undefined = undefined;
  signinError: string | undefined = undefined;
  loadingSignin: boolean = false;
  profileLogin: ISignInResponseData | undefined = undefined;

  constructor() {
    this.loadAccessToken();
    this.setApiAccessToken();

    makeObservable(this, {
      accessToken: observable,
      signinError: observable,
      loadingSignin: observable,
      profileLogin: observable,
      signinZaloAction: action,
      signinFacebookAction: action,
      logout: action,
      updateAccessTokenLogin: action,
      updateProfileLogin: action,
    });
  }

  signinZaloAction = async (zaloCode: string) => {
    this.loadingSignin = true;

    try {
      const { data } = await signinZaloAPI(zaloCode);

      const { accessToken } = data;

      this.accessToken = accessToken;

      this.profileLogin = {
        ...this.profileLogin,
        ...data,
      };

      this.loadingSignin = false;

      this.updateAccessTokenLocalStore();

      this.setApiAccessToken();

      popUpStore.openModal(EPopUp.CHANGE_PROFILE);
    } catch (error) {
      this.signinError = "Something went wrong!";
    } finally {
      this.loadingSignin = false;
    }
  };

  updateProfileLogin(profile: ISignInResponseData | any) {
    this.profileLogin = profile;
  }

  updateAccessTokenLogin(token: string) {
    this.accessToken = token;
  }

  signinFacebookAction = async (facebookCode: string) => {
    this.loadingSignin = true;

    try {
      const { data } = await signinFacebookAPI(facebookCode);

      const { accessToken } = data;

      this.accessToken = accessToken;

      this.profileLogin = {
        ...this.profileLogin,
        ...data,
      };

      this.loadingSignin = false;

      this.updateAccessTokenLocalStore();

      this.setApiAccessToken();

      popUpStore.openModal(EPopUp.CHANGE_PROFILE);
    } catch (error) {
      this.signinError = "Something went wrong!";
    } finally {
      this.loadingSignin = false;
    }
  };

  loadAccessToken() {
    const accessTokenJSON = localStorage.getItem("access_token");
    this.accessToken = accessTokenJSON
      ? JSON.parse(accessTokenJSON)
      : undefined;
  }

  updateAccessTokenLocalStore() {
    if (this.accessToken) {
      localStorage.setItem("access_token", JSON.stringify(this.accessToken));
    }
  }

  setApiAccessToken() {
    if (this.accessToken) {
      setApiAccessToken(this.accessToken);
    }
  }

  logout = () => {
    // Clear access token from local storage
    localStorage.removeItem("access_token");

    // Reset state variables
    this.accessToken = undefined;
    this.signinError = undefined;
    this.loadingSignin = false;
    this.profileLogin = undefined;

    userStore.resetUserProfile();
  };
}

const authenticationStore = new AuthenticationStore();

export default authenticationStore;

import { API } from "../api";

export interface IVoteWithAutheResponse {
  post?: {
    id: number;
    contentPost: string;
    imagePost: string;
    postID: string;
    postStatus: "PENDING" | "APPROVED" | "UNPUBLISH";
    createDate: string;
  };
  id?: number;
  voteDate?: string;
  voteStatus?: string;
  nameOfAuthor?: string;
}

export const voteWithAuthenAPI = (postId: string) => {
  return API.post<IVoteWithAutheResponse>(`/vote/post/${postId}`, {
    headers: {
      "Access-Control-Allow-Credential": "true",
    },
  });
};

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DetailPostDesktop } from "./detail-post-desktop.page";
import { DetailPostMobile } from "./detail-post-mobile.page";

interface IDetailPostProps {}

export function formatDate(timestamp: string): string {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const DetailPost: React.FC<IDetailPostProps> = observer((props) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsDesktop(true);
    }
  }, []);

  if (isDesktop) return <DetailPostDesktop />;

  return <DetailPostMobile />;
});

// components/change-profile.view.tsx
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import authenticationStore from "../../stores/authentication.store";
import userStore from "../../stores/user.store";
import AvatarCropper from "./avatar-cropper.view";
import { changeProfileAPI } from "../../apis";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import changeProfileStore from "../../stores/change-profile.store";
import { Loading } from "../loading";
import { useHistory, useLocation } from "react-router-dom";

type Props = {};

export const ChangeProfile: React.FC<Props> = observer(() => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorForm, setErrorForm] = useState("");

  const { search } = useLocation();

  const history = useHistory();

  const query = new URLSearchParams(search);

  const codeQuery = query.get("code");

  const stateQuery = query.get("state");

  useEffect(() => {
    if (codeQuery && stateQuery) {
      history.push("");
    }
  }, [codeQuery, history, stateQuery]);

  const handleFileChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      changeProfileStore.setSelectedFile(file);
      e.target.value = null;
    }
  };

  const handleProfileChange = async () => {
    popUpStore.setIsReadedEndNoti(true);
    changeProfileStore.setLoading(true);

    try {
      const { name, email, phoneNumber, croppedImage, isIgnoreAvatarGen } =
        changeProfileStore;

      if (email === "" && phoneNumber === "") {
        setErrorForm(
          "Bạn phải điền/thay đổi số điện thoại hoặc email để xác nhận!"
        );
        return;
      }

      setErrorForm("");

      const { data } = await changeProfileAPI(
        name,
        email,
        phoneNumber,
        isIgnoreAvatarGen,
        croppedImage || undefined
      );

      const { userProfile: oldProfile } = userStore;
      const { profileLogin: _oldProfile } = authenticationStore;

      userStore.updateUserProfile({
        ...oldProfile,
        ...data,
      });

      authenticationStore.updateProfileLogin({ ..._oldProfile, ...data });

      changeProfileStore.setLoading(false);
      popUpStore.closeModal();

      // Handle success
    } catch (error) {
      popUpStore.closeModal();
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
      // Handle error
    } finally {
      changeProfileStore.setLoading(false);
    }
  };

  return (
    <div className="w-10/12 md:w-5/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-8 relative">
      {changeProfileStore.loading && <Loading />}
      <div className="flex flex-col flex-wrap gap-2 md:gap-3 justify-center items-center w-full">
        <h2 className="text-2xl md:text-3xl font-bold text-center">
          Thông tin cá nhân
        </h2>
        <div
          className="flex flex-wrap items-center gap-2 mt-1"
          onClick={() => fileInputRef.current?.click()}
        >
          {!changeProfileStore.croppedImage ? (
            <Avatar
              className="!w-[70px] !h-[70px] md:!w-[90px] md:!h-[90px]"
              src={process.env.REACT_APP_API_ENDPOINT?.replace(
                "/api",
                authenticationStore.profileLogin?.avatar ||
                  userStore.userProfile?.avatar ||
                  ""
              )}
              alt={
                authenticationStore.profileLogin?.name ||
                userStore.userProfile?.name
              }
              round
            />
          ) : (
            <Avatar
              src={changeProfileStore.croppedImage}
              className="!w-[70px] !h-[70px] md:!w-[90px] md:!h-[90px]"
              round
            />
          )}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/gif, image/jpg, image/jpeg"
          id="avatarInput"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {changeProfileStore.selectedFile && (
          <AvatarCropper
            imageUrl={URL.createObjectURL(changeProfileStore.selectedFile)}
            onCrop={(croppedImageUrl: string) => {
              changeProfileStore.setCroppedImage(croppedImageUrl);
            }}
          />
        )}
        <div className="flex flex-wrap justify-start items-center gap-1 mt-4 self-start ml-4 md:ml-12">
          <input
            type="checkbox"
            defaultChecked={
              authenticationStore.profileLogin?.isIgnoreAvatarGen ||
              userStore.userProfile?.isIgnoreAvatarGen ||
              false
            }
            onChange={(e) =>
              changeProfileStore.setIsIgnoreAvatarGen(e.target.checked)
            }
            className="md:w-4 md:h-4"
          />
          <p className="text-[#464646] text-xs md:text-base">
            Tôi không muốn chèn avatar
          </p>
        </div>
        {/* INPUT name, email, or phoneNumber */}
        <div className="flex flex-col flex-wrap justify-center items-center w-11/12 gap-4 md:gap-6 mt-2">
          <div className="w-full flex items-center border border-[#464646] rounded-md p-2 md:p-3">
            <input
              type="text"
              placeholder="Name"
              defaultValue={
                authenticationStore.profileLogin?.name ||
                userStore.userProfile?.name ||
                ""
              }
              onChange={(e) => changeProfileStore.setName(e.target.value)}
              className="w-full outline-none text-xs md:text-base text-center text-[#464646]"
            />
          </div>
          <p className="text-center px-8 text-xs text-[#464646] md:text-base">
            Lưu Email hoặc Số điện thoại để xác minh nếu trúng giải
          </p>
          <div className="w-full flex items-center border border-[#464646] rounded-md p-2 md:p-3">
            <FaEnvelope className="text-[#7A7A7A] text-sm md:text-base mr-2" />
            <input
              type="email"
              placeholder="Email của bạn"
              defaultValue={
                authenticationStore.profileLogin?.email === "_" ||
                userStore.userProfile?.email === "_"
                  ? ""
                  : authenticationStore.profileLogin?.email ||
                    userStore.userProfile?.email ||
                    ""
              }
              onChange={(e) => changeProfileStore.setEmail(e.target.value)}
              className="w-full outline-none text-xs md:text-base text-[#464646] placeholder:text-[#7A7A7A]"
            />
          </div>
          <div className="w-full flex items-center border border-[#464646] rounded-md p-2 md:p-3">
            <FaPhone className="text-[#7A7A7A] text-sm mr-2" />
            <input
              type="text"
              placeholder="Phone Number"
              defaultValue={
                userStore.userProfile?.phoneNumber !== "_"
                  ? userStore.userProfile?.phoneNumber || ""
                  : ""
              }
              onChange={(e) =>
                changeProfileStore.setPhoneNumber(e.target.value)
              }
              className="w-full outline-none text-xs md:text-base text-[#464646] placeholder:text-[#7A7A7A]"
            />
          </div>
          {errorForm !== "" && (
            <p className="text-red-300 text-center text-xs">{errorForm}</p>
          )}
          <button
            className="py-2 md:py-3 text-white w-full rounded-[99px] bg-[#2196F3] text-sm md:text-base font-semibold text-center mt-4 md:mt-2"
            onClick={handleProfileChange}
          >
            Xác nhận
          </button>
        </div>
        <p
          className="text-center text-xs flex flex-col gap-1.5 flex-wrap items-center w-full px-3 mt-3 cursor-pointer"
          onClick={() => popUpStore.closeModal()}
        >
          <span className="text-center text-xs md:text-base text-[#464646] underline">
            Bỏ qua
          </span>
        </p>
      </div>
    </div>
  );
});

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import formStore from "../../stores/form.store";
import authenticationStore from "../../stores/authentication.store";
import { useHistory } from "react-router-dom";
import "./form-star-trek.css";
import { FormStarTrekMobile } from "./form-star-trek.page-mobile";
import { FormStarTrekDesktop } from "./form-star-trek.page-desktop";

interface IFormStarTrekProps {}

export const FormStarTrek: React.FC<IFormStarTrekProps> = observer((props) => {
  const history = useHistory();

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (!authenticationStore.accessToken) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, authenticationStore.accessToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStore.stepNumber]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsDesktop(true);
    }
  }, []);

  if (isDesktop) return <FormStarTrekDesktop />;

  return <FormStarTrekMobile />;
});

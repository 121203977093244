import React, { useEffect, useMemo, useState, useRef } from "react";
import { observer } from "mobx-react";
import logo from "../../assets/home/traveloka-logo.png";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import authenticationStore from "../../stores/authentication.store";
import Avatar from "react-avatar";
import { useHistory, useLocation } from "react-router-dom";
import userStore from "../../stores/user.store";
import formStore from "../../stores/form.store";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosCloseCircleOutline } from "react-icons/io";

interface INavBarProps {}

export const NavBar: React.FC<INavBarProps> = observer((props) => {
  const history = useHistory();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const codeQuery = query.get("code");

  const stateQuery = query.get("state");

  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!authenticationStore.profileLogin && !userStore.userProfile) {
      userStore.getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStore.profileLogin, userStore.userProfile]);

  useEffect(() => {
    if (
      !codeQuery &&
      !stateQuery &&
      !popUpStore.isReadedEndNoti &&
      !popUpStore.popUpType
    ) {
      popUpStore.openModal(EPopUp.END_NOTI);
    }
  }, [codeQuery, stateQuery]);

  const handleOpenNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    if (isNavOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavOpen]);

  const avatarSrc = useMemo(() => {
    if (authenticationStore.profileLogin?.avatar) {
      const avatarPath = authenticationStore.profileLogin.avatar;
      if (typeof avatarPath === "string") {
        return `${process.env.REACT_APP_CDN_URL}${avatarPath}`;
      }
    }

    if (userStore.userProfile?.avatar) {
      const avatarPath = userStore.userProfile.avatar;
      if (typeof avatarPath === "string") {
        return `${process.env.REACT_APP_CDN_URL}${avatarPath}`;
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStore.profileLogin?.avatar, userStore.userProfile?.avatar]);

  return (
    <>
      <nav className="fixed w-full z-10 bg-[#079AF2] top-0 min-h-[72px] md:min-h-[88px] flex flex-wrap pb-4 md:pb-6 px-4 md:px-60 justify-between items-end md:shadow">
        <div
          className="w-[40%] md:w-[12%] flex flex-wrap items-start cursor-pointer"
          onClick={() => {
            formStore.resetForm();
            history.push("/");
          }}
        >
          <img src={logo} alt="tvlk" className="w-full pb-1 " />
        </div>
        <div className="w-[55%] flex flex-wrap justify-end items-center gap-3 md:gap-6">
          {/* {avatarSrc ? (
            <Avatar
              className="border-[0.4px] !w-[32px] !h-[32px] md:!w-[44px] md:!h-[44px]"
              src={avatarSrc}
              round
              // size="32"
              onClick={() => history.push("/my-post")}
            />
          ) : (
            <button
              className="bg-none px-3 md:px-5 py-0.5 md:py-1 border-2 rounded-[99px] text-white text-sm md:text-base"
              onClick={() => popUpStore.openModal(EPopUp.LOGIN_JOURNEY1)}
            >
              Tham gia ngay
            </button>
          )} */}
          <div onClick={handleOpenNav} className="cursor-pointer">
            <RxHamburgerMenu className="text-white md:text-[32px]" />
          </div>
        </div>
      </nav>
      {isNavOpen && (
        <div className="fixed top-0 md:top-[88px] left-0 md:left-auto md:right-60 w-full md:w-[300px] h-full md:h-[470px] rounded-lg bg-[#079AF2] opacity-90 md:opacity-100 md:shadow-lg z-10 px-8">
          <ul
            ref={navRef}
            className="w-full md:h-full flex flex-wrap flex-col gap-2 md:gap-4 justify-start md:justify-center items-center mt-[30%] md:mt-0"
          >
            <li
              onClick={() => history.push("/")}
              className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
            >
              Trang chủ
            </li>
            {/* <li
              onClick={() => history.push("/post-list")}
              className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
            >
              Bài tiêu biểu
            </li> */}
            {(authenticationStore.profileLogin || userStore.userProfile) && (
              <>
                <li
                  onClick={() => {
                    history.push("/my-post");
                    window.location.reload();
                  }}
                  className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
                >
                  Bài viết của bạn
                </li>
                <li
                  onClick={() => {
                    // TODO
                    handleOpenNav();
                    popUpStore.openModal(EPopUp.CHANGE_PROFILE);
                  }}
                  className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
                >
                  Thông tin cá nhân
                </li>
              </>
            )}
            <li
              onClick={() => {
                popUpStore.openModal(EPopUp.RULE);
              }}
              className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
            >
              Thể lệ & giải thưởng
            </li>
            <li className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer">
              <a
                href="https://www.traveloka.com/vi-vn/promotion/didulichdattraveloka?utm_id=YOIPZpKE&utm_source=facebook.com&utm_medium=referral&utm_campaign=HYG1746-VN-ALL-SOCMED-SOCIAL-Vn_-_Cross-Product_Hygiene-Startrek&utm_term=PRO-Microsite"
                target="_blank"
                rel="noreferrer"
              >
                Ưu đãi từ Traveloka
              </a>
            </li>
            {(authenticationStore.profileLogin || userStore.userProfile) && (
              <li
                onClick={() => {
                  authenticationStore.logout();
                  handleOpenNav();
                }}
                className="w-full flex flex-wrap justify-center items-center py-2 text-white md:text-base cursor-pointer"
              >
                Đăng xuất
              </li>
            )}
            <li onClick={handleOpenNav} className="cursor-pointer">
              <IoIosCloseCircleOutline
                color="white"
                className="w-[30px] h-[30px] md:w-[34px] md:h-[34px]"
              />
            </li>
          </ul>
        </div>
      )}
    </>
  );
});

import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

interface AvatarCropperProps {
  imageUrl?: string;
  onCrop?: (croppedImageUrl: string) => void;
}

const AvatarCropper: React.FC<AvatarCropperProps> = observer(
  ({ imageUrl, onCrop }) => {
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      if (imageUrl) {
        const image = new Image();

        image.src = imageUrl;
        image.onload = () => {
          const canvas = canvasRef.current;

          if (!canvas) return; // Exit early if canvas is null

          const context = canvas.getContext("2d");
          if (context) {
            const aspectRatio = image.width / image.height;
            const canvasSize = 200; // Adjust the desired size of the cropped image

            canvas.width = canvasSize;
            canvas.height = canvasSize;

            const width =
              image.width > image.height
                ? image.height * aspectRatio
                : image.width;
            const height =
              image.width > image.height
                ? image.height
                : image.width / aspectRatio;

            const offsetX = (image.width - width) / 2;
            const offsetY = (image.height - height) / 2;

            context.drawImage(
              image,
              offsetX,
              offsetY,
              width,
              height,
              0,
              0,
              canvasSize,
              canvasSize
            );

            const croppedImageUrl = canvas.toDataURL("image/png");
            setCroppedImage(croppedImageUrl);
            if (onCrop) {
              onCrop(croppedImageUrl);
            }
          }
        };
      }
    }, [imageUrl, onCrop]);

    return (
      <div className="hidden">
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {croppedImage && <img src={croppedImage} alt="Cropped" />}
      </div>
    );
  }
);

export default AvatarCropper;

import React from "react";
import { observer } from "mobx-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import Avatar from "react-avatar";

interface IPostFeatureResponse {
  contentPost?: string;
  imagePost?: string;
  postID?: string;
  createDate?: string;
  voteCount?: number;
  userAvatar?: string;
  userName?: string;
}

interface ICarouselTopQuoteProps {
  posts: IPostFeatureResponse[];
}

export const CarouselTopQuote: React.FC<ICarouselTopQuoteProps> = observer(
  ({ posts }) => {
    return (
      <Carousel
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`top-[45%] absolute left-2 z-20 ${
                !hasPrev ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={hasPrev ? clickHandler : undefined}
            >
              <IoIosArrowDropleft size={25} color="white" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`top-[45%] absolute right-2 z-20 ${
                !hasNext ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={hasNext ? clickHandler : undefined}
            >
              <IoIosArrowDropright size={25} color="white" />
            </div>
          );
        }}
      >
        {posts.map((post, index) => (
          <div
            key={index}
            className="relative overflow-hidden w-full h-0 pb-[100%]"
          >
            <img
              src={post.imagePost}
              className="absolute inset-0 object-cover w-full h-[110%] blur-[5px] transform"
              alt={`Post ${index + 1}`}
            />
            <div className="absolute inset-0 flex items-center my-auto pt-4 pl-[15%] bg-[#0075DC] bg-opacity-55">
              <div className="flex flex-wrap flex-col gap-3 my-auto items-start">
                <div className="flex flex-wrap flex-col gap-0 my-auto items-start">
                  <span className="text-white font-bold text-2xl">
                    {post.contentPost}
                  </span>
                </div>

                <div className="flex flex-wrap gap-2 items-center text-white">
                  <Avatar src={post.userAvatar} round size="24" />
                  <span>{post.userName}</span>
                </div>

                <div className="flex flex-wrap gap-2 items-center text-white pl-0.5">
                  <IoMdHeart size={20} color="#FF6000" />
                  <span>{post.voteCount}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    );
  }
);

import { API } from "../api";

export interface IDetailPostResponse {
  postID: string;
  contentPost: string;
  imagePost: string;
  createDate: string;
  postStatus: "PENDING" | "APPROVED" | "UNPUBLISH";
  avatar: string;
  name: string;
  voteCount: string;
  votes: Array<IVote>;
}

export interface IVote {
  name: string;
  avatar: string;
  voteDate: string;
  thirdPartyId: string;
}

export const loadDetailPostAPI = (id: string) => {
  return API.get<IDetailPostResponse>(`/post/detail-with-vote/${id}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

import { observer } from "mobx-react";
import React from "react";
import { Footer, NavBar } from "../../views";

interface IRuleProps {}

export const Rule: React.FC<IRuleProps> = observer((props) => {
  return (
    <div className="relative">
      <NavBar />

      <div className="flex flex-wrap flex-col w-full mt-24 gap-2 px-4 mb-20">
        <div className="text-lg font-bold">
          <h1 className="text-xl font-bold mb-4 text-center text-[#0171CE]">
            CUỘC THI NHẢ CHỮ NHẬN QUÀ
          </h1>

          <div className="flex flex-col gap-1 flex-wrap px-4">
            <h3 className="text-lg font-bold mb-2 text-center text-[#464646]">
              Cách thức tham gia
            </h3>
            <p className="text-sm text-[#464646] flex flex-col gap-2">
              <p>
                <b className="text-black underline">Bước 1:</b> Người dự thi
                truy cập trang web cuộc thi tại Website:
                https://www.traveloka.com/vi-vn/vi-vu-cung-lan-ngoc
              </p>
              <p>
                <b className="text-black underline">Bước 2:</b> Người dự thi
                đăng nhập bằng tài khoản Zalo hoặc Facebook để có thể tham gia
                cuộc thi
              </p>
              <p>
                <b className="text-black underline">Bước 3:</b> Người dự thi bắt
                đầu tạo bài dự thi theo các hướng dẫn trên website:
                <p className="flex flex-col pl-4">
                  <span>
                    ○ Tải hình ảnh hoặc sử dụng ảnh có sẵn từ Ban tổ chức (BTC)
                  </span>
                  <span>
                    ○ Sáng tác câu trích dẫn truyền cảm hứng ngắn gọn kèm câu
                    "Đi du lịch đặt Traveloka"
                  </span>
                  <span>
                    ○ Đăng tải bài dự thi ○ Nhập email hoặc số điện thoại để xác
                    minh.
                  </span>
                </p>
              </p>
              <p>
                <b className="text-black underline">Bước 4:</b> Người dự thi
                chia sẻ link bài thi của mình trên trang cá nhân (Facebook/
                Zalo) để kêu gọi bạn bè bình chọn bằng cách bấm nút Thả Tim vào
                bài dự thi trên Website.
              </p>
            </p>
          </div>

          <p className="text-sm text-[#464646] flex flex-col gap-2 px-4 mt-8">
            <h3 className="text-lg font-bold mb-2 text-center text-[#464646]">
              Bài dự thi hợp lệ
            </h3>
            <p>
              ● Nội dung bài dự thi gồm: 01 câu đầu diễn tả một điều hiển nhiên
              hoặc được nhiều người chấp nhận mà bạn thấy trong cuộc sống hoặc
              trải nghiệm du lịch; 01 câu cuối là câu “Đi du lịch, đặt
              Traveloka”.
            </p>
            <p>
              ● Nội dung bài dự thi cần đảm bảo yếu tố ngữ pháp Việt Nam, không
              viết tắt và không pha trộn các ngôn ngữ khác.
            </p>
            <p>
              ● Nội dung bài dự thi không được nhắc đến các thương hiệu khác.
            </p>
            <p>
              ● Bài dự thi (bao gồm hình ảnh và nội dung) đảm bảo thuần phong mỹ
              tục, không vi phạm đạo đức xã hội và các quy định về pháp luật
              Việt Nam hiện hành.
            </p>
          </p>

          <p className="text-sm text-[#464646] flex flex-col gap-2 px-4 mt-8">
            <h3 className="text-lg font-bold mb-2 text-center text-[#464646]">
              Giải thưởng cuộc thi
            </h3>
            <p>
              ● 01 Giải “Hot Hit”: 01 phiếu quà tặng du lịch Traveloka trị giá
              10,000,000 VNĐ Dành cho 01 bài dự thi hợp lệ do BTC lựa chọn, đáp
              ứng các tiêu chí:
            </p>
            <ul className="list-disc pl-5">
              <li>
                Có tối thiểu 50 lượt bình chọn (Tim) tại thời điểm kết thúc
                chương trình.
              </li>
              <li>
                Đạt số điểm cao nhất từ BTC với cơ cấu điểm:
                <ul className="list-disc pl-5">
                  <li>Nội dung hài hước</li>
                  <li>Nội dung truyền cảm hứng</li>
                  <li>Nội dung mới mẻ, sáng tạo</li>
                  <li>Hình ảnh đẹp</li>
                </ul>
              </li>
            </ul>
            <p className="pl-3">
              Bài dự thi đạt Giải “Hot Hit” có cơ hội được trưng bày tại 02 biển
              bảng lớn (Billboard) và một số màn hình LED ở Hà Nội và Thành phố
              Hồ Chí Minh.
            </p>
            <p className="mt-2">
              ● 10 Giải “Rần Rần”: mỗi giải 01 phiếu quà tặng du lịch Traveloka
              trị giá 2,000,000 VNĐ Dành cho 10 bài dự thi hợp lệ có số lượt
              bình chọn (Tim) cao nhất vào cuối chương trình.
            </p>
            <p className="pl-3">
              Bài dự thi thắng giải sẽ có cơ hội được trình chiếu trên một số
              màn hình LED ở Hà Nội và Thành phố Hồ Chí Minh.
            </p>
          </p>

          <p className="text-sm text-[#464646] flex flex-col gap-2 mt-8 px-4">
            <h3 className="text-lg font-bold mb-2 text-center text-[#464646]">
              Quy định khác
            </h3>
            <ul className="list-disc pl-5">
              <li>
                Nội dung bài dự thi phải đảm bảo chưa từng được sử dụng cho mục
                đích thương mại.
              </li>
              <li>
                Một người dự thi có thể gửi nhiều bài dự thi, nếu người dự thi
                có nhiều bài dự thi đủ điều kiện trúng giải thì chỉ được nhận 01
                giải cao nhất. Các bài còn lại của người dự thi này sẽ bị hủy
                kết quả và chọn bài từ người dự thi khác đủ tiêu chí để thay
                thế.
              </li>
              <li>
                Trường hợp có nhiều bài dự thi trùng khớp nội dung và đều thỏa
                điều kiện chấm giải, BTC sẽ chọn bài dự thi được gửi sớm hơn.
              </li>
              <li>
                BTC có toàn quyền từ chối và loại bỏ các bài dự thi nộp muộn, có
                dấu hiệu gian lận hoặc các bài dự thi có nội dung không hợp lệ.
              </li>
              <li>
                Người dự thi phải đảm bảo rằng nội dung và hình ảnh mình sử dụng
                trong bài dự thi không vi phạm quyền sở hữu trí tuệ của bất kỳ
                bên thứ ba nào khác.
              </li>
              <li>
                Người thắng giải đồng ý để BTC được toàn quyền sử dụng nội dung
                và hình ảnh bài thi thắng giải, thông tin người dự thi (tên và
                ảnh đại diện) cho mục đích quảng cáo, phát triển thương hiệu,
                quảng bá và tiếp thị dịch vụ trên các phương tiện truyền thông
                mà không phải trả bất kỳ khoản phí nào.
              </li>
              <li>
                Trong trường hợp bất khả kháng (bao gồm nhưng không giới hạn
                thiên tai, lũ lụt, sét đánh, khủng bố, lỗi kỹ thuật…), BTC có
                quyền quyết định thay đổi chương trình và thông báo với người
                tham dự trong thời gian sớm nhất.
              </li>
              <li>
                Nhân viên hiện đang công tác tại Traveloka, các cá nhân hoặc
                nhân viên thuộc tổ chức hợp tác với Traveloka trong chương trình
                này được tham gia cuộc thi, nhưng bài dự thi không được xem xét
                chấm giải và nhận giải.
              </li>
              <li>
                Traveloka có quyền hủy kết quả, không trao giải hoặc thu hồi các
                giải thưởng đối với các trường hợp có dấu hiệu gian lận hoặc
                trục lợi từ chương trình.
              </li>
              <li>
                Giải thưởng chỉ được nhận bằng hiện vật, không được quy đổi
                thành tiền mặt, không được thay đổi thông tin người nhận giải.
                Người thắng giải có trách nhiệm tuân thủ các nghĩa vụ thuế thu
                nhập cá nhân liên quan đến Cuộc thi, giải thưởng và/hoặc sử dụng
                hoặc hưởng giải thưởng.
              </li>
              <li>
                Bằng việc tham gia vào chương trình, người tham gia đã đọc, hiểu
                rõ và đồng ý với tất cả các quy định của chương trình.
              </li>
              <li>
                Trong mọi trường hợp, quyền quyết định cuối cùng thuộc về Ban tổ
                chức.
              </li>
            </ul>
          </p>
        </div>
      </div>

      {/* <CarouselPromotion /> */}

      {/* <PopupModal /> */}

      <Footer />
    </div>
  );
});

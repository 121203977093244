import { action, makeObservable, observable } from "mobx";

export enum EPopUp {
  LOGIN_JOURNEY1 = "LOGIN_JOURNEY1",
  LOGIN_JOURNEY2 = "LOGIN_JOURNEY2",
  LOGIN_EMPTY = "LOGIN_EMPTY",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  CONTACT_FORM = "CONTACT_FORM",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  DETAIL_VOTES = "DETAIL_VOTES",
  ERROR_UPLOAD = "ERROR_UPLOAD",
  SUCCESS_CONTACT = "SUCCESS_CONTACT",
  ERROR_CONTACT = "ERROR_CONTACT",
  WARNING_POST = "WARNING_POST",
  ERROR_COMMON = "ERROR_COMMON",
  ERROR_DETAIL = "ERROR_DETAIL",
  RULE = "RULE",
  VOTED_POST = "VOTED_POST",
  VOTED_SUCCESS = "VOTED_SUCCESS",
  CHANGE_PROFILE = "CHANGE_PROFILE",
  FULL_POST = "FULL_POST",
  PENDING_POST = "PENDING_POST",
  END_NOTI = "END_NOTI",
}

class PopUpStore {
  popUpType: EPopUp | undefined = undefined;
  isReadedEndNoti: boolean = false;

  constructor() {
    makeObservable(this, {
      popUpType: observable,
      isReadedEndNoti: observable,
      openModal: action,
      closeModal: action,
      setIsReadedEndNoti: action,
    });

    const storedIsReadedEndNoti = sessionStorage.getItem("isReadedEndNoti");
    if (storedIsReadedEndNoti !== null) {
      this.isReadedEndNoti = JSON.parse(storedIsReadedEndNoti);
    }
  }

  openModal(popUpType: EPopUp) {
    this.popUpType = popUpType;
  }

  closeModal() {
    this.popUpType = undefined;
  }

  setIsReadedEndNoti(readed: boolean) {
    this.isReadedEndNoti = readed;
    // Store the updated value in session storage
    sessionStorage.setItem("isReadedEndNoti", JSON.stringify(readed));
  }
}

const popUpStore = new PopUpStore();
export default popUpStore;

import { API } from "../api";

export const changeProfileAPI = (
  name: string,
  email: string,
  phoneNumber?: string,
  isIgnoreAvatarGen?: boolean,
  avatarStringBase64?: string
) => {
  const formData = new URLSearchParams();

  formData.append("name", name);

  if (email) {
    formData.append("email", email);
  }

  if (phoneNumber) {
    formData.append("phoneNumber", phoneNumber);
  }

  if (typeof isIgnoreAvatarGen !== "undefined") {
    formData.append("isIgnoreAvatarGen", isIgnoreAvatarGen.toString());
  }

  if (avatarStringBase64) {
    formData.append("avatarStringBase64", avatarStringBase64);
  }

  return API.put("/users/change-profile", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

import { API } from "../api";

export interface IPostFeatureResponse {
  contentPost?: string;
  imagePost?: string;
  postID?: string;
  createDate?: string;
  voteCount?: number;
  userAvatar?: string;
  userName?: string;
}
export const loadFeaturePostsAPI = (limit: number = 10) => {
  return API.get<IPostFeatureResponse[]>(`/post/features?limit=${limit}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

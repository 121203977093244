import { action, makeObservable, observable } from "mobx";
import authenticationStore from "./authentication.store";
import { getUserProfileAPI } from "../apis";

interface IUserProfile {
  avatar?: string;
  email?: string;
  gaveVotes?: any[];
  method?: string;
  name?: string;
  posts?: any[];
  registerDate?: string;
  thirdPartyId?: string;
  userStatus?: string;
  phoneNumber?: string;
  isIgnoreAvatarGen?: boolean;
}

class UserStore {
  userProfile: IUserProfile | undefined = undefined;
  loadingUserProfile: boolean = false;

  constructor() {
    makeObservable(this, {
      userProfile: observable,
      getUserProfile: action,
      resetUserProfile: action,
      updateUserProfile: action,
    });
  }

  resetUserProfile() {
    this.userProfile = undefined;
  }

  updateUserProfile(profile: IUserProfile) {
    this.userProfile = profile;
  }

  getUserProfile = async () => {
    if (
      !this.userProfile &&
      !authenticationStore.profileLogin &&
      authenticationStore.accessToken
    ) {
      this.loadingUserProfile = true;

      const { data } = await getUserProfileAPI();

      this.userProfile = { ...data };
      this.loadingUserProfile = false;
      try {
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUserProfile = false;
      }
    }
  };
}

const userStore = new UserStore();

export default userStore;

import { API } from "../api";
import { IPostFeatureResponse } from "./load-feature-posts.api";

export const loadTopPostsAPI = (pageNumber: number = 1) => {
  return API.get<IPostFeatureResponse[]>(
    `/post/top-vote?pageNumber=${pageNumber}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "clean-request": "no-clean",
      },
    }
  );
};

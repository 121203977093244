import { ISignInResponseData } from "../../models";
import { API } from "../api";

export const signinFacebookAPI = (facebookCode: string) => {
  const formData = new URLSearchParams();
  formData.append("facebookCode", facebookCode);

  return API.post<ISignInResponseData>("/auth/signin-facebook", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

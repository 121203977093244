import { makeAutoObservable } from "mobx";
import { ITemp, arrayTemp } from "./form-store.type";
import { uploadPostAPI } from "../apis";
import popUpStore, { EPopUp } from "./popup.store";
import {
  IPostFeatureResponse,
  loadFeaturePostsAPI,
} from "../apis/post/load-feature-posts.api";

class FormStore {
  stepNumber: number = 1;
  uploadImage: File | null = null;
  tempSrc: string | undefined = arrayTemp[1].src;
  quote: string = "Dòng một của bạn ở đây\nDòng hai ở vị trí này";
  position: number = arrayTemp[1].position;
  errorUploadFile: string | undefined;
  localUploadImageUrl: string | undefined;
  textColor: string = "white";
  isAcceptRule: boolean = true;
  loadingSubmit: boolean = false;
  urlImageSuccess: string = "";
  errorQuote: string | undefined;
  postIDSuccess: string | undefined;
  featurePost: IPostFeatureResponse[] = [];
  loadingFeaturePosts: boolean = false;
  isDarkMode: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadFeaturePosts = async () => {
    this.loadingFeaturePosts = true;

    try {
      const { data } = await loadFeaturePostsAPI(10);

      this.featurePost = data;

      this.loadingFeaturePosts = false;
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingFeaturePosts = false;
    }
  };

  onSubmitPost = async () => {
    if (this.errorUploadFile || this.errorQuote) return;

    try {
      this.loadingSubmit = true;

      const selectedTemplateId =
        arrayTemp.find((temp) => temp.src === this.tempSrc)?.id || 1;

      // Call the uploadPostAPI function to upload the post
      const { data } = await uploadPostAPI(
        this.uploadImage,
        this.quote,
        this.position,
        this.textColor,
        selectedTemplateId
      );

      if (data.error_code !== 1) {
        this.loadingSubmit = false;
        return popUpStore.openModal(EPopUp.ERROR_UPLOAD);
      }

      this.urlImageSuccess = data.url;
      this.postIDSuccess = data.postID;

      this.stepNumber = 4;

      this.loadingSubmit = false;
    } catch (error) {
      console.error("Error uploading post:", error);
    } finally {
      this.loadingSubmit = false;
    }
  };

  updateIsAcceptRule(e: any) {
    this.isAcceptRule = e.target.checked;
  }

  updateTextColor(color: string) {
    this.textColor = color;
  }

  updatePosition(pos: number) {
    this.position = pos;
  }

  updateQuote(e: any) {
    const quote = e.target.value;

    const scriptTagPattern = /(<[^>]+>)|(https?:\/\/)/gi;

    if (scriptTagPattern.test(quote)) {
      this.errorQuote = "Trích dẫn không được chứa thẻ hoặc HTML không hợp lệ";
    }

    // Check if the quote has at least 5 words
    if (quote.length < 20 || quote.length > 90) {
      this.errorQuote = "Trích dẫn ít nhất 20 kí tự và không quá 90 ký tự";
    }

    // Check if the quote has more than 2 rows (lines)
    const lines = quote.split("\n");
    if (lines.length > 2) {
      this.errorQuote = "Trích dẫn chỉ tối đa 2 dòng";
    }

    if (lines.length <= 2 && quote.length >= 20 && quote.length <= 90) {
      this.errorQuote = undefined;
    }

    this.quote = quote;
  }

  updateStep(step: number) {
    this.stepNumber = step;
  }

  updateTemp(template: ITemp) {
    // reset upload image
    this.uploadImage = null;
    this.localUploadImageUrl = undefined;

    this.tempSrc = template.src;
    this.position = template.position;
    this.textColor = template.textColor || "white";
  }

  handleChangeFileInput = (e: any) => {
    e.preventDefault();

    if (!e.target.files[0].type.match("image/*")) {
      return (this.errorUploadFile = "File sai định dạng");
    }

    if (e.target.files[0].size > 10000000) {
      return (this.errorUploadFile = "File không được vượt quá 10MB");
    }

    this.errorUploadFile = undefined;
    this.tempSrc = undefined;

    // Proceed with setting new values
    const localImageUrl = window.URL.createObjectURL(e.target.files[0] as any);
    this.localUploadImageUrl = localImageUrl;
    this.uploadImage = e.target.files[0];

    // Reset the value of the file input element
    e.target.value = null;
  };

  resetForm() {
    this.stepNumber = 1;
    this.uploadImage = null;
    this.tempSrc = arrayTemp[1].src;
    this.quote = "Dòng một của bạn ở đây\nDòng hai ở vị trí này";
    this.position = arrayTemp[1].position;
    this.errorUploadFile = undefined;
    this.localUploadImageUrl = undefined;
    this.textColor = "white";
    this.isAcceptRule = false;
    this.loadingSubmit = false;
    this.urlImageSuccess = "";
    this.errorQuote = undefined;
  }

  setIsDarkMode = (isDarkMode: boolean) => {
    this.isDarkMode = isDarkMode;
  };
}

const formStore = new FormStore();

export default formStore;

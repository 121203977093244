import { API } from "../api";

export const uploadPostAPI = (
  imageFile: any,
  quote: string,
  position: number,
  textColor: string,
  tempNumber: number
) => {
  const formData = new FormData();

  // Append image file or tempNumber to FormData based on condition
  if (imageFile) {
    formData.append("image", imageFile);
  } else {
    formData.append("tempNumber", tempNumber.toString());
  }

  // Append other form fields
  formData.append("quote", quote);
  formData.append("position", position.toString());

  if (textColor === "white") {
    formData.append("textColor", textColor);
  } else {
    formData.append("textColor", "blue");
  }

  return API.post<any>("/post/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "clean-request": "no-clean",
    },
  });
};

import { API } from "../api";

export const sentContactAPI = (
  name: string,
  phoneOrEmail: string,
  message: string,
  userThirdPartyID?: string | number
) => {
  const formData = new URLSearchParams();

  formData.append("name", name);
  formData.append("phoneNumber", phoneOrEmail);
  formData.append("message", message);

  if (userThirdPartyID) {
    formData.append("userThirdPartyID", userThirdPartyID as string);
  }

  return API.post("/contact", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

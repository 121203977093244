// image temp source import
import temp0 from "../assets/form/temp_empty.jpg";
// import temp1 from "../assets/form/NDLN-pick-up-1.png";
// import temp2 from "../assets/form/NDLN-pick-up-2.png";
// import temp3 from "../assets/form/NDLN-pick-up-3.png";
// import temp4 from "../assets/form/NDLN-pick-up-4.png";

import temp1 from "../assets/form/tmpl-1-min.jpg";
import temp2 from "../assets/form/tmpl-2-min.jpg";
import temp3 from "../assets/form/tmpl-3-min.jpg";
import temp4 from "../assets/form/tmpl-4-min.jpg";
import temp5 from "../assets/form/tmpl-5-min.jpg";
import temp6 from "../assets/form/tmpl-6-min.jpg";
import temp7 from "../assets/form/tmpl-7-min.jpg";
import temp8 from "../assets/form/tmpl-8-min.jpg";
import temp9 from "../assets/form/tmpl-9-min.jpg";
import temp10 from "../assets/form/tmpl-10-min.jpg";
import temp11 from "../assets/form/tmpl-11-min.jpg";
import temp12 from "../assets/form/tmpl-12-min.jpg";
import temp13 from "../assets/form/tmpl-13-min.jpg";
import temp14 from "../assets/form/tmpl-14-min.jpg";

export interface ITemp {
  id: number;
  src: string;
  position: number;
  textColor?: string;
}
export const arrayTextPositon = [
  { positionNumber: 1, des: "Góc trái trên" },
  { positionNumber: 2, des: "Góc phải trên" },
  { positionNumber: 4, des: "Góc trái dưới" },
  { positionNumber: 3, des: "Góc phải dưới" },
];

export const positionClassesMap: Map<number, string> = new Map([
  [1, "left-[8%] top-[10%] md:top-[12%]"],
  [2, "right-[8%] top-[10%] md:top-[12%]"],
  [3, "right-[8%] bottom-[14%] md:bottom-[11%]"],
  [4, "left-[8%] bottom-[14%] md:bottom-[11%]"],
]);

export const arrayTemp: ITemp[] = [
  {
    id: 0,
    src: temp0,
    position: 1,
    textColor: "white",
  },
  {
    id: 1,
    src: temp1,
    position: 1,
    textColor: "white",
  },
  {
    id: 2,
    src: temp2,
    position: 2,
    textColor: "white",
  },
  {
    id: 3,
    src: temp3,
    position: 3,
    textColor: "white",
  },
  {
    id: 4,
    src: temp4,
    position: 4,
    textColor: "#005498",
  },
  {
    id: 5,
    src: temp5,
    position: 1,
    textColor: "white",
  },
  {
    id: 6,
    src: temp6,
    position: 2,
    textColor: "white",
  },
  {
    id: 7,
    src: temp7,
    position: 1,
    textColor: "#005498",
  },
  {
    id: 8,
    src: temp8,
    position: 3,
    textColor: "white",
  },
  {
    id: 9,
    src: temp9,
    position: 1,
    textColor: "#005498",
  },
  {
    id: 10,
    src: temp10,
    position: 2,
    textColor: "white",
  },
  {
    id: 11,
    src: temp11,
    position: 3,
    textColor: "white",
  },
  {
    id: 12,
    src: temp12,
    position: 4,
    textColor: "white",
  },
  {
    id: 13,
    src: temp13,
    position: 2,
    textColor: "white",
  },
  {
    id: 14,
    src: temp14,
    position: 4,
    textColor: "white",
  },
];

import { ISignInResponseData } from "../../models";
import { API } from "../api";

export const signinZaloAPI = (zaloCode: string) => {
  const formData = new URLSearchParams();
  formData.append("zaloCode", zaloCode);

  return API.post<ISignInResponseData>("/auth/signin-zalo", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "clean-request": "no-clean",
    },
  });
};

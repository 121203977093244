import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import starTrekBanner from "../../assets/home/main_banner.png";
import starTrekBannerPC from "../../assets/home/PC_main_banner.jpg";
import starTrekSubBanner from "../../assets/home/sub_banner.png";
import prizePC from "../../assets/home/Prize_PC.png";

import authenticationStore from "../../stores/authentication.store";
import popUpStore, { EPopUp } from "../../stores/popup.store";

interface IBannerHomeProps {
  isAddRule?: boolean;
}

export const BannerHome: React.FC<IBannerHomeProps> = observer(
  ({ isAddRule = false }) => {
    const history = useHistory();

    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      if (window.innerWidth > 768) {
        setIsDesktop(true);
      }
    }, []);

    return (
      <>
        <div className="flex flex-wrap justify-center items-center relative md:pt-[88px] md:bg-[#02A8FF]">
          <img
            src={
              isDesktop
                ? `${process.env.REACT_APP_CDN_URL}${starTrekBannerPC}`
                : `${process.env.REACT_APP_CDN_URL}${starTrekBanner}`
            }
            alt="banner-startrek"
          />
          {isDesktop && (
            <img
              src={`${process.env.REACT_APP_CDN_URL}${prizePC}`}
              alt="Giải thưởng"
              className="absolute left-[50%] 2xl:bottom-[20%] xl:bottom-[16%] lg:bottom-[12%] md:bottom-[10%] w-[504px] h-[540px]"
            />
          )}
          {isDesktop && (
            <div
              className={`absolute left-[62%] 2xl:top-[490px] xl:top-[440px] lg:top-[380px] md:top-[340px] flex flex-wrap justify-center flex-col gap-2 md:gap-6`}
            >
              <button
                className="bg-[#FF7902] px-16 md:px-20 py-1 md:py-2 rounded-[99px] text-white text-sm md:text-base"
                onClick={() => {
                  // if (authenticationStore.accessToken) {
                  //   history.push("/form-startrek");
                  // } else {
                  //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                  // }
                }}
              >
                Nhả chữ ngay
              </button>
            </div>
          )}
          <div
            className={`absolute ${!isAddRule ? "bottom-7" : "bottom-4"} mx-auto md:left-[18%] md:bottom-[14%] flex flex-wrap justify-center flex-col gap-2 md:gap-6`}
          >
            <button
              className="bg-[#FF7902] px-16 md:px-20 py-1 md:py-2 rounded-[99px] text-white text-sm md:text-base"
              onClick={() => {
                // if (authenticationStore.accessToken) {
                //   history.push("/form-startrek");
                // } else {
                //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                // }
              }}
            >
              Nhả chữ ngay
            </button>
            {isDesktop && (
              <div
                className="text-base text-white underline text-center"
                onClick={() => popUpStore.openModal(EPopUp.RULE)}
              >
                Chi tiết thể lệ và giải thưởng
              </div>
            )}
            {isAddRule && (
              <div
                className="text-xs text-white underline text-center"
                onClick={() => popUpStore.openModal(EPopUp.RULE)}
              >
                Chi tiết thể lệ và giải thưởng
              </div>
            )}
          </div>
        </div>
        {!isAddRule && !isDesktop && (
          <div className="flex flex-wrap justify-center items-center relative w-full bg-white pt-5">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${starTrekSubBanner}`}
              alt="banner-startrek"
            />
            <div className="absolute bottom-8 mx-auto flex flex-wrap justify-center flex-col gap-3">
              <button
                className="bg-[#FF7902] px-16 py-1 rounded-[99px] text-white text-sm"
                onClick={() => {
                  // if (authenticationStore.accessToken) {
                  //   history.push("/form-startrek");
                  // } else {
                  //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                  // }
                }}
              >
                Nhả chữ ngay
              </button>
              <div
                className="text-xs text-white underline text-center"
                onClick={() => popUpStore.openModal(EPopUp.RULE)}
              >
                Chi tiết thể lệ và giải thưởng
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

import React from "react";
import { observer } from "mobx-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import "./carousel-promotion.style.css";

import image1 from "../../assets/home/_Fly.jpg";
import image2 from "../../assets/home/_Hotel.jpg";
import image3 from "../../assets/home/_Activities.jpg";
import image4 from "../../assets/home/_Bus.jpg";

interface ICarouselPromotionProps {
  isDesktop?: boolean;
}

const images = [image1, image2, image3, image4];
const promotionUrl =
  "https://www.traveloka.com/vi-vn/promotion/didulichdattraveloka?utm_id=YOIPZpKE&utm_source=facebook.com&utm_medium=referral&utm_campaign=HYG1746-VN-ALL-SOCMED-SOCIAL-Vn_-_Cross-Product_Hygiene-Startrek&utm_term=PRO-Microsite";

const createCarouselItemImage = (index: number, imageSrc: string) => {
  return (
    <img
      key={index}
      src={`${process.env.REACT_APP_CDN_URL}${imageSrc}`}
      alt={`Promotion ${index + 1}`}
      className={`carousel-promotion-item rounded-[10px] md:w-[240px]`}
      style={{ cursor: "pointer" }}
    />
  );
};

const baseChildren = (
  <div>
    {images.map((imageSrc, index) => createCarouselItemImage(index, imageSrc))}
  </div>
);

export const CarouselPromotion: React.FC<ICarouselPromotionProps> = observer(
  ({ isDesktop = false }) => {
    return (
      <>
        <div
          className={`w-full flex flex-col flex-wrap gap-0 md:gap-2 justify-center items-center py-8 md:-translate-y-[80px] bg-white`}
        >
          <h2 className="text-center text-white text-lg font-bold text-shadow-blue md:text-shadow-blue-pc px-8 md:text-3xl">
            Ninh Dương Lan Ngọc
          </h2>
          <h2 className="text-center text-white text-lg font-bold text-shadow-blue md:text-shadow-blue-pc px-8 md:text-3xl">
            tặng bạn mới ngàn ưu đãi
          </h2>
          <p className="text-center text-sm text-[#464646] md:pt-2 md:text-base md:font-semibold">
            Khi đặt các dịch vụ trên Traveloka
          </p>
        </div>

        {isDesktop ? (
          <div
            className="-translate-y-[80px] flex flex-wrap justify-center items-center gap-3 pb-6"
            onClick={() =>
              window.open(promotionUrl, "_blank", "noopener,noreferrer")
            }
          >
            {baseChildren.props.children}
          </div>
        ) : (
          <Carousel
            className="carousel-promotion bg-white pb-8"
            selectedItem={1}
            centerMode
            centerSlidePercentage={50}
            autoPlay={false}
            showThumbs={false}
            showStatus={false}
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`top-[45%] absolute left-2 z-20 ${
                    !hasPrev ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={hasPrev ? clickHandler : undefined}
                >
                  <IoIosArrowDropleft size={25} color="white" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`top-[45%] absolute right-2 z-20 ${
                    !hasNext ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={hasNext ? clickHandler : undefined}
                >
                  <IoIosArrowDropright size={25} color="white" />
                </div>
              );
            }}
            onClickItem={() =>
              window.open(promotionUrl, "_blank", "noopener,noreferrer")
            }
          >
            {baseChildren.props.children}
          </Carousel>
        )}
      </>
    );
  }
);

// CoreAuthenticationStore.js
import { makeAutoObservable } from "mobx";
import {
  signinAndVoteFacebookAPI,
  signinAndVoteZaloAPI,
  voteWithAuthenAPI,
} from "../apis";
import popUpStore, { EPopUp } from "./popup.store";
import authenticationStore from "./authentication.store";

class VoteStore {
  loadingVote: boolean = false;
  votedMessage: string = "";
  postIdSuccess: string = "";
  nameOfAuthor: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  signinZaloAndVote = async (postId: string, zaloCode: string) => {
    this.loadingVote = true;

    try {
      const { data } = await signinAndVoteZaloAPI(postId, zaloCode);

      const {
        accessToken,
        voteFail,
        name,
        isComeback,
        avatar,
        thirdPartyId,
        method,
        nameOfAuthor,
      } = data;

      authenticationStore.updateAccessTokenLogin(accessToken);
      authenticationStore.updateAccessTokenLocalStore();
      authenticationStore.setApiAccessToken();

      authenticationStore.updateProfileLogin({ ...data });

      this.nameOfAuthor = nameOfAuthor;

      if (voteFail) {
        popUpStore.openModal(EPopUp.VOTED_POST);
      } else {
        this.postIdSuccess = postId;
        popUpStore.openModal(EPopUp.VOTED_SUCCESS);
      }
    } catch (error) {
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
    } finally {
      this.loadingVote = false;
    }
  };

  signinFacebookAndVote = async (postId: string, facebookCode: string) => {
    this.loadingVote = true;

    try {
      const { data } = await signinAndVoteFacebookAPI(postId, facebookCode);

      const {
        accessToken,
        voteFail,
        name,
        isComeback,
        avatar,
        thirdPartyId,
        method,
        nameOfAuthor,
      } = data;

      this.nameOfAuthor = nameOfAuthor;

      authenticationStore.updateAccessTokenLogin(accessToken);
      authenticationStore.updateAccessTokenLocalStore();
      authenticationStore.setApiAccessToken();

      authenticationStore.updateProfileLogin({ ...data });

      if (voteFail) {
        popUpStore.openModal(EPopUp.VOTED_POST);
      } else {
        this.postIdSuccess = postId;
        popUpStore.openModal(EPopUp.VOTED_SUCCESS);
      }
    } catch (error) {
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
    } finally {
      this.loadingVote = false;
    }
  };

  voteWithAuth = async (postId: string) => {
    this.loadingVote = true;

    try {
      const { data } = await voteWithAuthenAPI(postId);

      const { post } = data;

      this.postIdSuccess = post?.postID || postId;
      this.nameOfAuthor = data.nameOfAuthor || "";

      popUpStore.openModal(EPopUp.VOTED_SUCCESS);
    } catch (error) {
      popUpStore.openModal(EPopUp.ERROR_UPLOAD);
    } finally {
      this.loadingVote = false;
    }
  };
}

const voteStore = new VoteStore();

export default voteStore;

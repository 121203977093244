import React, { useRef } from "react";
import { observer } from "mobx-react";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import { MdOutlineClose } from "react-icons/md";
import authenticationStore from "../../stores/authentication.store";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import userStore from "../../stores/user.store";
import detailStore from "../../stores/detail-post.store";
import voteStore from "../../stores/vote.store";
import { ChangeProfile } from "../change-profile/change-profile.view";
import contactFormStore from "../../stores/contact-form.store";

interface IPopupModalProps {}

export const PopupModal: React.FC<IPopupModalProps> = observer(() => {
  const fullName = authenticationStore.profileLogin?.name;

  const history = useHistory();

  const lastTwoWords = fullName ? fullName.split(" ").slice(-2).join(" ") : "";

  return (
    <div
      className={`fixed inset-0 bg-black z-10 bg-opacity-50 flex justify-center items-center w-full h-full md:left-[50%] md:transform md:-translate-x-[50%] ${
        popUpStore.popUpType ? "" : "hidden"
      }`}
    >
      {popUpStore.popUpType === EPopUp.LOGIN_JOURNEY1 && (
        <div className="w-10/12 md:w-4/12 bg-white rounded-lg p-6 md:px-12 flex flex-wrap justify-center items-center py-16 md:py-20 relative">
          <div
            className="absolute top-5 right-5 flex flex-wrap justify-center items-center"
            onClick={() => popUpStore.closeModal()}
          >
            <MdOutlineClose size={30} />
          </div>
          <div className="flex flex-col flex-wrap gap-6 justify-center items-center w-full">
            <h2 className="text-2xl font-bold text-center uppercase">
              Đăng nhập
            </h2>
            <p className="text-center text-xs flex flex-col gap-1.5 flex-wrap items-center w-full px-4">
              <span className="text-center text-xs text-[#464646] md:text-base">
                Đăng nhập để BTC có thể xác minh nếu bạn trúng giải.
              </span>
            </p>
            <div className="flex flex-col flex-wrap justify-center items-center w-11/12 gap-4">
              {!window.navigator.userAgent.includes("FBAN") && (
                <a
                  href={`https://oauth.zaloapp.com/v4/permission?app_id=515363946425148194&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=zalo_jn1`}
                  className="py-3 text-white w-full rounded-[99px] bg-[#2196F3] text-sm text-center md:text-base"
                  target="_top"
                >
                  Đăng nhập bằng Zalo
                </a>
              )}
              {!window.navigator.userAgent.includes("Zalo") && (
                <a
                  className="py-3 text-white w-full rounded-[99px] bg-[#0866FF] text-sm text-center md:text-base"
                  target="_top"
                  href={`https://www.facebook.com/v15.0/dialog/oauth?client_id=447784454570906&redirect_uri=${process.env.REACT_APP_REDIRECT_URI || "https://startrek.traveloka.link/"}&state=facebook_jn1`}
                >
                  Đăng nhập bằng Facebook
                </a>
              )}
            </div>
            <p className="text-center text-xs flex flex-col gap-1.5 flex-wrap items-center w-full px-3 mt-3">
              <span className="text-center text-xs text-[#464646] italic md:text-base">
                Traveloka hứa và đảm bảo không tự ý đăng nội dung lên trang cá
                nhân của bạn.
              </span>
            </p>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.LOGIN_SUCCESS && (
        <div className="w-10/12 md:w-4/12 bg-white rounded-lg p-6 md:px-12 flex flex-wrap justify-center items-center py-16 md:py-20 relative">
          <div className="flex flex-col flex-wrap gap-5 md:gap-8 justify-center items-center w-full">
            <h2 className="text-2xl font-bold text-center uppercase">
              {authenticationStore.profileLogin?.isComeback
                ? "Chào mừng trở lại!"
                : "Xin chào"}
            </h2>
            {authenticationStore.profileLogin && (
              <div className="flex flex-wrap justify-center items-center w-11/12 gap-3 md:gap-4">
                <Avatar
                  className="font-bold !w-[40px] !h-[40px] md:!w-[56px] md:!h-[56px]"
                  src={process.env.REACT_APP_API_ENDPOINT?.replace(
                    "/api",
                    authenticationStore.profileLogin?.avatar || ""
                  )}
                  alt={authenticationStore.profileLogin?.name}
                  round
                />

                <h4 className="text-secondary md:text-base">{lastTwoWords}</h4>
              </div>
            )}
            <p className="text-center text-sm flex flex-col justify-center items-center gap-0">
              <span className="text-center text-sm mb-2 text-[#464646] font-bold md:text-base">
                Bạn sẵn sàng chưa?
              </span>
              <span className="text-center text-sm text-[#464646] font-bold md:text-base">
                Chỉ cần 2 bước
              </span>
              <span className="text-center text-sm text-[#464646] font-bold md:text-base">
                Bước 1: Chọn hình nền billboard
              </span>
              <span className="text-center text-sm text-[#464646] font-bold md:text-base">
                Bước 2: “Nhả chữ” tâm đắc và LÊN SÓNG thôi
              </span>
            </p>
            <button
              className="py-1 md:py-2 text-white w-full md:w-8/12 rounded-[99px] bg-[#079AF2] text-sm md:text-base"
              onClick={() => {
                history.push("form-startrek");
                popUpStore.closeModal();
              }}
            >
              Bắt đầu ngay!
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.RULE && (
        <div className="w-11/12 md:w-8/12 bg-white px-2 md:px-8 transform -translate-y-20 flex flex-wrap flex-col justify-start items-center pt-12 relative rounded-lg relative">
          <div
            className="absolute top-5 right-5 flex flex-wrap justify-center items-center"
            onClick={() => popUpStore.closeModal()}
          >
            <MdOutlineClose size={30} />
          </div>
          <h1 className="text-lg font-bold mb-4 text-center text-[#0171CE] mt-4">
            CUỘC THI NHẢ CHỮ NHẬN QUÀ
          </h1>
          <div className="flex flex-wrap flex-col w-full gap-2 px-4 max-h-[240px] md:max-h-[300px] overflow-y-scroll">
            <div className="text-lg font-bold">
              <div className="flex flex-col gap-1 flex-wrap px-4 pb-4 pt-2">
                <h3 className="text-base font-bold mb-1 text-center text-[#464646]">
                  Thời gian diễn ra
                </h3>
                <p className="text-sm text-[#464646] flex flex-col gap-2 text-center">
                  <p className="text-sm italic text-center">
                    Từ ngày 13/05/2024 ngày 07/06/2024
                  </p>
                </p>
              </div>
              <div className="flex flex-col gap-1 flex-wrap px-4 pb-6 pt-2">
                <h3 className="text-base font-bold mb-1 text-center text-[#464646]">
                  Đối tượng tham gia
                </h3>
                <p className="text-sm text-[#464646] flex flex-col gap-2">
                  <p className="text-sm">
                    Công dân Việt Nam, đang sinh sống tại Việt Nam, từ đủ 18
                    tuổi tuổi tính đến ngày bắt đầu cuộc thi.
                  </p>
                </p>
              </div>
              <div className="flex flex-col gap-1 flex-wrap px-4 pb-6 pt-2">
                <h3 className="text-base font-bold mb-2 text-center text-[#464646]">
                  Cách thức tham gia
                </h3>
                <p className="text-sm text-[#464646] flex flex-col gap-2">
                  <p className="text-sm">
                    <b className="text-black underline text-sm">Bước 1:</b>{" "}
                    Người dự thi truy cập trang web cuộc thi tại Website:
                    https://www.traveloka.com/vi-vn/vi-vu-cung-lan-ngoc
                  </p>
                  <p className="text-sm">
                    <b className="text-black underline text-sm">Bước 2:</b>{" "}
                    Người dự thi đăng nhập bằng tài khoản Zalo hoặc Facebook để
                    có thể tham gia cuộc thi
                  </p>
                  <p className="text-sm">
                    <b className="text-black underline text-sm">Bước 3:</b>{" "}
                    Người dự thi bắt đầu tạo bài dự thi theo các hướng dẫn trên
                    website:
                    <p className="flex flex-col pl-4 text-sm">
                      <span className="text-sm">
                        ○ Tải hình ảnh hoặc sử dụng ảnh có sẵn từ Ban tổ chức
                        (BTC)
                      </span>
                      <span className="text-sm">
                        ○ Sáng tác câu trích dẫn truyền cảm hứng ngắn gọn kèm
                        câu "Đi du lịch đặt Traveloka"
                      </span>
                      <span className="text-sm">
                        ○ Đăng tải bài dự thi ○ Nhập email hoặc số điện thoại để
                        xác minh.
                      </span>
                    </p>
                  </p>
                  <p className="text-sm">
                    <b className="text-black underline text-sm">Bước 4:</b>{" "}
                    Người dự thi chia sẻ link bài thi của mình trên trang cá
                    nhân (Facebook/ Zalo) để kêu gọi bạn bè bình chọn bằng cách
                    bấm nút Thả Tim vào bài dự thi trên Website.
                  </p>
                </p>
              </div>

              <p className="text-sm text-[#464646] flex flex-col gap-2 px-4 mt-4">
                <h3 className="text-base font-bold mb-2 text-center text-[#464646]">
                  Bài dự thi hợp lệ
                </h3>
                <p className="text-sm">
                  ● Nội dung bài dự thi gồm: 01 câu đầu diễn tả một điều hiển
                  nhiên hoặc được nhiều người chấp nhận mà bạn thấy trong cuộc
                  sống hoặc trải nghiệm du lịch; 01 câu cuối là câu “Đi du lịch,
                  đặt Traveloka”.
                </p>
                <p className="text-sm">
                  ● Nội dung bài dự thi cần đảm bảo yếu tố ngữ pháp Việt Nam,
                  không viết tắt và không pha trộn các ngôn ngữ khác.
                </p>
                <p className="text-sm">
                  ● Nội dung bài dự thi không được nhắc đến các thương hiệu
                  khác.
                </p>
                <p className="text-sm">
                  ● Bài dự thi (bao gồm hình ảnh và nội dung) đảm bảo thuần
                  phong mỹ tục, không vi phạm đạo đức xã hội và các quy định về
                  pháp luật Việt Nam hiện hành.
                </p>
              </p>

              <p className="text-sm text-[#464646] flex flex-col gap-2 px-4 mt-8">
                <h3 className="text-base font-bold mb-2 text-center text-[#464646]">
                  Giải thưởng cuộc thi
                </h3>
                <p className="text-sm">
                  ● 01 Giải “Hot Hit”: 01 phiếu quà tặng du lịch Traveloka trị
                  giá 10,000,000 VNĐ Dành cho 01 bài dự thi hợp lệ do BTC lựa
                  chọn, đáp ứng các tiêu chí:
                </p>
                <ul className="list-disc pl-5 text-sm">
                  <li className="text-sm">
                    Có tối thiểu 50 lượt bình chọn (Tim) tại thời điểm kết thúc
                    chương trình.
                  </li>
                  <li className="text-sm">
                    Đạt số điểm cao nhất từ BTC với cơ cấu điểm:
                    <ul className="list-disc pl-5">
                      <li className="text-sm">Nội dung hài hước</li>
                      <li className="text-sm">Nội dung truyền cảm hứng</li>
                      <li className="text-sm">Nội dung mới mẻ, sáng tạo</li>
                      <li className="text-sm">Hình ảnh đẹp</li>
                    </ul>
                  </li>
                </ul>
                <p className="text-sm pl-3">
                  Bài dự thi đạt Giải “Hot Hit” có cơ hội được trưng bày tại 02
                  biển bảng lớn (Billboard) và một số màn hình LED ở Hà Nội và
                  Thành phố Hồ Chí Minh.
                </p>
                <p className="text-sm mt-2">
                  ● 10 Giải “Rần Rần”: mỗi giải 01 phiếu quà tặng du lịch
                  Traveloka trị giá 2,000,000 VNĐ Dành cho 10 bài dự thi hợp lệ
                  có số lượt bình chọn (Tim) cao nhất vào cuối chương trình.
                </p>
                <p className="text-sm pl-3">
                  Bài dự thi thắng giải sẽ có cơ hội được trình chiếu trên một
                  số màn hình LED ở Hà Nội và Thành phố Hồ Chí Minh.
                </p>
              </p>

              <p className="text-sm text-[#464646] flex flex-col gap-2 mt-8 px-4">
                <h3 className="text-base font-bold mb-2 text-center text-[#464646]">
                  Quy định khác
                </h3>
                <ul className="list-disc pl-5">
                  <li className="text-sm">
                    Nội dung bài dự thi phải đảm bảo chưa từng được sử dụng cho
                    mục đích thương mại.
                  </li>
                  <li className="text-sm">
                    Một người dự thi có thể gửi nhiều bài dự thi, nếu người dự
                    thi có nhiều bài dự thi đủ điều kiện trúng giải thì chỉ được
                    nhận 01 giải cao nhất. Các bài còn lại của người dự thi này
                    sẽ bị hủy kết quả và chọn bài từ người dự thi khác đủ tiêu
                    chí để thay thế.
                  </li>
                  <li className="text-sm">
                    Trường hợp có nhiều bài dự thi trùng khớp nội dung và đều
                    thỏa điều kiện chấm giải, BTC sẽ chọn bài dự thi được gửi
                    sớm hơn.
                  </li>
                  <li className="text-sm">
                    BTC có toàn quyền từ chối và loại bỏ các bài dự thi nộp
                    muộn, có dấu hiệu gian lận hoặc các bài dự thi có nội dung
                    không hợp lệ.
                  </li>
                  <li className="text-sm">
                    Người dự thi phải đảm bảo rằng nội dung và hình ảnh mình sử
                    dụng trong bài dự thi không vi phạm quyền sở hữu trí tuệ của
                    bất kỳ bên thứ ba nào khác.
                  </li>
                  <li className="text-sm">
                    Người dự thi đồng ý để BTC được toàn quyền sử dụng nội dung
                    và hình ảnh bài thi, thông tin người dự thi (tên và ảnh đại
                    diện) cho mục đích quảng cáo, phát triển thương hiệu, quảng
                    bá và tiếp thị dịch vụ trên các phương tiện truyền thông mà
                    không phải trả bất kỳ khoản phí nào.
                  </li>
                  <li className="text-sm">
                    Trong trường hợp bất khả kháng (bao gồm nhưng không giới hạn
                    thiên tai, lũ lụt, sét đánh, khủng bố, lỗi kỹ thuật…), BTC
                    có quyền quyết định thay đổi chương trình và thông báo với
                    người tham dự trong thời gian sớm nhất.
                  </li>
                  <li className="text-sm">
                    Nhân viên hiện đang công tác tại Traveloka, các cá nhân hoặc
                    nhân viên thuộc tổ chức hợp tác với Traveloka trong chương
                    trình này được tham gia cuộc thi, nhưng bài dự thi không
                    được xem xét chấm giải và nhận giải.
                  </li>
                  <li className="text-sm">
                    Traveloka có quyền hủy kết quả, không trao giải hoặc thu hồi
                    các giải thưởng đối với các trường hợp có dấu hiệu gian lận
                    hoặc trục lợi từ chương trình.
                  </li>
                  <li className="text-sm">
                    Giải thưởng chỉ được nhận bằng hiện vật, không được quy đổi
                    thành tiền mặt, không được thay đổi thông tin người nhận
                    giải. Người thắng giải có trách nhiệm tuân thủ các nghĩa vụ
                    thuế thu nhập cá nhân liên quan đến Cuộc thi, giải thưởng
                    và/hoặc sử dụng hoặc hưởng giải thưởng.
                  </li>
                  <li className="text-sm">
                    Bằng việc tham gia vào chương trình, người tham gia đã đọc,
                    hiểu rõ và đồng ý với tất cả các quy định của chương trình.
                  </li>
                  <li className="text-sm">
                    Trong mọi trường hợp, quyền quyết định cuối cùng thuộc về
                    Ban tổ chức.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-center items-center mt-8 mb-12">
            <button
              className="px-12 py-1 text-sm text-center bg-[#00A1FF] rounded-[99px] text-white cursor-pointer"
              onClick={() => {
                // if (authenticationStore.accessToken) {
                //   popUpStore.closeModal();
                //   history.push("/form-startrek");
                // } else {
                //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                // }
                popUpStore.closeModal();
              }}
            >
              Đồng ý!
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.ERROR_UPLOAD && (
        <div className="w-10/12 md:w-4/12 rounded-xl bg-white p-6 flex flex-wrap flex-col justify-start items-center py-12 relative">
          <div className="flex flex-col flex-wrap gap-4 justify-center items-center w-full h-full content-stretch">
            <h2 className="text-xl font-bold text-center w-full text-red-500">
              Xin lỗi, có lỗi xảy ra!
            </h2>

            <p className="py-2 w-10/12 text-center text-sm">
              Vui lòng thử lại, hoặc chụp ảnh màn hình và liên hệ với BTC qua
              email minigamevn@traveloka.com với tiêu đề "Cuộc Thi Nhả Chữ Nhận
              Quà"
            </p>
            <button
              className="py-2 text-white w-10/12 rounded-[99px] bg-red-400 text-sm"
              onClick={() => {
                popUpStore.closeModal();
              }}
            >
              Đóng
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.VOTED_POST && (
        <div className="w-10/12 md:w-4/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-16 relative">
          <div className="flex flex-col flex-wrap gap-4 justify-center items-center w-full">
            <h2 className="text-2xl font-bold text-center uppercase">
              {authenticationStore.profileLogin?.isComeback
                ? "Chào mừng trở lại!"
                : "Xin chào bạn mới"}
            </h2>
            <p className="text-center text-red-400 text-sm flex flex-col justify-center items-center gap-0">
              Xin lỗi, bạn đã bình chọn cho bài viết này, vui lòng bình chọn cho
              bài viết khác!
            </p>
            <button
              className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm"
              onClick={() => {
                popUpStore.closeModal();
                window.location.reload();
              }}
            >
              Quay lại bài viết
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.VOTED_SUCCESS && (
        <div className="w-10/12 md:w-3/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-20 relative">
          <div className="flex flex-col flex-wrap gap-5 justify-center items-center w-full">
            <h2 className="text-2xl font-bold text-center">VOTE THÀNH CÔNG!</h2>
            <h2 className="text-center text-xs text-[#464646] px-12">
              Bạn {voteStore.nameOfAuthor} thì chơi rồi, sao{" "}
              {authenticationStore.profileLogin?.name ||
                userStore.userProfile?.name}{" "}
              còn chưa chơi?
            </h2>
            <button
              className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm"
              onClick={() => {
                history.push("form-startrek");
                window.location.reload();
                popUpStore.closeModal();
              }}
            >
              Tham gia thử thách!
            </button>
            <p
              className="py-2 text-[#464646] w-full rounded-[99px] text-center text-xs  underline"
              onClick={() => {
                popUpStore.closeModal();
                window.location.reload();
              }}
            >
              Quay lại bài viết
            </p>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.LOGIN_JOURNEY2 && (
        <div className="w-10/12 md:w-4/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-16 relative">
          <div
            className="absolute top-5 right-5 flex flex-wrap justify-center items-center"
            onClick={() => popUpStore.closeModal()}
          >
            <MdOutlineClose size={30} />
          </div>
          <div className="flex flex-col flex-wrap gap-6 justify-center items-center w-full">
            <h2 className="text-xl font-bold text-center uppercase">
              Đăng nhập và bình chọn
            </h2>
            <div className="flex flex-col flex-wrap justify-center items-center w-11/12 gap-4">
              {!window.navigator.userAgent.includes("FBAN") && (
                <a
                  href={`https://oauth.zaloapp.com/v4/permission?app_id=515363946425148194&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=zalo_jn2-${detailStore.detailPost?.postID}`}
                  className="py-3 text-white w-full rounded-[99px] bg-[#2196F3] text-sm text-center"
                  target="_top"
                >
                  Đăng nhập bằng Zalo
                </a>
              )}
              {!window.navigator.userAgent.includes("Zalo") && (
                <a
                  target="_top"
                  className="py-3 text-white w-full rounded-[99px] bg-[#0866FF] text-sm text-center"
                  href={`https://www.facebook.com/v15.0/dialog/oauth?client_id=447784454570906&redirect_uri=${process.env.REACT_APP_REDIRECT_URI || "https://startrek.traveloka.link/"}&state=facebook_jn2-${detailStore.detailPost?.postID}`}
                >
                  Đăng nhập bằng Facebook
                </a>
              )}
            </div>
            <p className="text-center text-xs">
              Đăng nhập ngay và bình chọn cho bài dự thi này.
            </p>
            <p className="text-center text-xs italic">
              Traveloka xác minh lượt bình chọn bằng Zalo và Facebook, xin hứa
              và đảm bảo không tự ý đăng nội dung lên trang cá nhân của bạn.
            </p>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.CHANGE_PROFILE && <ChangeProfile />}

      {popUpStore.popUpType === EPopUp.FULL_POST && (
        <div className="w-10/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-20 relative">
          <div className="flex flex-col flex-wrap gap-5 justify-center items-center w-full">
            <h2 className="text-2xl font-bold text-center">Xin lỗi!</h2>
            <h2 className="text-center text-xs text-[#464646] px-12">
              Chỉ xem được các bài hai ngày gần đây!
            </h2>
            <button
              className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm"
              onClick={() => {
                popUpStore.closeModal();
              }}
            >
              Đóng
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.PENDING_POST && (
        <div className="w-10/12 md:w-5/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-20 relative">
          <div className="flex flex-col flex-wrap gap-5 justify-center items-center w-full">
            <h2 className="flex flex-col justify-center items-center">
              <span className="text-xl font-bold text-center uppercase">
                BÀI DỰ THI
              </span>
              <span className="text-xl font-bold text-center uppercase">
                ĐANG CHỜ XÉT DUYỆT
              </span>
            </h2>
            <h2 className="px-3 flex flex-col flex-wrap gap-2">
              <span className="text-center text-xs text-[#464646]">
                Bài đang chờ duyệt nên bị mờ,
              </span>
              <span className="text-center text-xs text-[#464646]">
                Nhưng bạn vẫn có thể Thả tim vô điều kiện.
              </span>
            </h2>
            <button
              className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm"
              onClick={() => {
                popUpStore.closeModal();
              }}
            >
              Xem và thả tim
            </button>
          </div>
        </div>
      )}

      {popUpStore.popUpType === EPopUp.CONTACT_FORM && (
        <div className="w-10/12 md:w-5/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-20 relative">
          <div
            className="absolute top-5 right-5 flex flex-wrap justify-center items-center"
            onClick={() => popUpStore.closeModal()}
          >
            <MdOutlineClose size={30} />
          </div>
          {!contactFormStore.sentSuccess ? (
            <div className="flex flex-col flex-wrap gap-5 justify-center items-center w-full">
              <h2 className="flex flex-col justify-center items-center">
                <span className="text-xl font-bold text-center uppercase">
                  Liên hệ BTC
                </span>
              </h2>
              <h2 className="px-3 flex flex-col flex-wrap gap-2">
                <span className="text-center text-xs text-[#464646]">
                  Vui lòng điền thông tin để gửi về ban tổ chức
                </span>
              </h2>
              <form className="w-full flex flex-col gap-4">
                <div>
                  <input
                    type="text"
                    defaultValue={contactFormStore.name}
                    onChange={(e) => contactFormStore.setName(e.target.value)}
                    placeholder="Tên của bạn"
                    disabled={contactFormStore.loading}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <input
                    placeholder="Số điện thoại/Email"
                    type="text"
                    value={contactFormStore.phoneOrEmail}
                    onChange={(e) =>
                      contactFormStore.setPhoneOrEmail(e.target.value)
                    }
                    disabled={contactFormStore.loading}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Câu hỏi/Góp ý của bạn"
                    value={contactFormStore.message}
                    onChange={(e) =>
                      contactFormStore.setMessage(e.target.value)
                    }
                    disabled={contactFormStore.loading}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                {contactFormStore.error && (
                  <div className="px-1 text-red-300 text-sm text-center">
                    {contactFormStore.error}
                  </div>
                )}
                <button
                  type="button"
                  disabled={contactFormStore.loading}
                  onClick={() => contactFormStore.submitForm()}
                  className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm disabled:opacity-50"
                >
                  {contactFormStore.loading ? "Đang gửi..." : "Gửi thông tin"}
                </button>
              </form>
            </div>
          ) : (
            <div className="flex flex-col flex-wrap gap-3 justify-center items-center w-full">
              <h2 className="flex flex-col justify-center items-center">
                <span className="text-lg font-bold text-center">
                  Cảm ơn bạn đã liên hệ với BTC!
                </span>
              </h2>
              <h2 className="px-3 flex flex-col flex-wrap gap-2">
                <span className="text-center text-xs text-[#464646]">
                  BTC sẽ liên hệ với bạn trong thời gian ngắn nhất.
                </span>
              </h2>
            </div>
          )}
        </div>
      )}

      {popUpStore.popUpType === EPopUp.END_NOTI && (
        <div className="w-10/12 md:w-4/12 bg-white rounded-lg p-6 flex flex-wrap justify-center items-center py-16 relative">
          <div className="flex flex-col flex-wrap gap-6 justify-center items-center w-full">
            <h2 className="text-xl font-bold text-center uppercase">
              Cuộc thi kết thúc
            </h2>
            <p className="text-center text-xs">
              Chúc mừng các tác giả đã trúng giải và xin cảm ơn tất cả các cao
              thủ đã nhiệt tình “flex trình độ văn thơ" của mình cùng Traveloka.
            </p>
            <a
              href="https://vi-vu-cung-lan-ngoc.traveloka.link/winners"
              target="_blank"
              rel="noreferrer"
              className="text-center text-xs text-[#0075DC]"
            >
              <p className="text-center text-xs">
                Xem danh sách trúng giải tại đây
              </p>
            </a>

            <a
              href="https://fb.watch/sLqkH86g6O/"
              target="_blank"
              rel="noreferrer"
              className="text-center text-xs text-[#0075DC]"
            >
              <p className="text-center text-xs">Hướng dẫn nhận giải</p>
            </a>

            <button
              type="button"
              onClick={() => {
                popUpStore.closeModal();
                // popUpStore.setIsReadedEndNoti(true);

                // if (authenticationStore.accessToken) {
                //   popUpStore.closeModal();
                // } else {
                //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                // }
              }}
              className="py-2 text-white w-full rounded-[99px] bg-[#079AF2] text-sm disabled:opacity-50"
            >
              Đồng ý
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

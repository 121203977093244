import { makeAutoObservable } from "mobx";
import { loadDetailPostAPI, IDetailPostResponse } from "../apis";

class DetailStore {
  detailPost: IDetailPostResponse | undefined = undefined;
  loading: boolean = false;
  error: string | undefined = undefined;
  currentPage: number = 1;
  itemsPerPage: number = 5; // Number of votes per page

  constructor() {
    makeAutoObservable(this);
  }

  async loadDetailPost(id: string) {
    this.loading = true;
    this.error = undefined;

    try {
      const response = await loadDetailPostAPI(id);
      this.detailPost = response.data;
    } catch (error) {
      this.error = "Bài viết không tồn tại!";
    } finally {
      this.loading = false;
    }
  }

  // Function to handle changing the current page
  setCurrentPage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  // Function to get the currently displayed votes based on pagination
  getDisplayedVotes() {
    if (!this.detailPost || !this.detailPost.votes) return [];

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    return this.detailPost.votes.slice(startIndex, endIndex);
  }
}

const detailStore = new DetailStore();
export default detailStore;

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import Avatar from "react-avatar";
import postStore from "../../stores/postStore.store";
import { useHistory } from "react-router-dom";
import "./feature-post.style.css";

interface IFeaturePostProps {}

export const FeaturePost: React.FC<IFeaturePostProps> = observer((props) => {
  const history = useHistory();
  useEffect(() => {
    postStore.loadFeaturePosts();
  }, []);

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsDesktop(true);
    }
  }, []);

  if (!postStore.featurePost.length)
    return <div className="w-full h-[4rem] bg-[#0180DF]" />;

  return (
    <div className="md:bg-[#0A9AF2] md:transform md:-translate-y-[100px] md:pb-8 feature-post_wrapper">
      <div className="bg-white md:bg-[#0A9AF2] px-4 pt-8 pb-4 md:pb-8 flex flex-wrap flex-col md:flex-row justify-center items-center gap-1 md:gap-2">
        <h3 className="text-[#005498] md:text-white md:text-3xl text-[18px] uppercase text-base text-center font-[1000]">
          HÉ LỘ TOP {postStore.featurePost.length}
        </h3>
        <h3 className="text-[#005498] md:text-white md:text-3xl text-[18px] uppercase text-base text-center font-[1000]">
          BÀI THI HAY HO MỚI NHẤT
        </h3>
      </div>
      <Carousel
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        centerMode={isDesktop}
        selectedItem={isDesktop ? 2 : 0}
        centerSlidePercentage={isDesktop ? 25 : 100}
        className="bg-[#0A9AF2]"
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`top-[45%] md:top-[110%] absolute left-2 md:left-16 z-[8] ${
                !hasPrev ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={hasPrev ? clickHandler : undefined}
            >
              <IoIosArrowDropleft
                color="white"
                className="w-[25px] h-[25px] md:w-[48px] md:h-[48px]"
              />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`top-[45%] md:top-[110%] absolute right-2 md:right-16 z-[8] ${
                !hasNext ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={hasNext ? clickHandler : undefined}
            >
              <IoIosArrowDropright
                color="white"
                className="w-[25px] h-[25px] md:w-[48px] md:h-[48px]"
              />
            </div>
          );
        }}
      >
        {postStore.featurePost.map((post, index) => (
          <div key={index} className="md:pr-2">
            <div
              className="relative overflow-hidden w-full h-0 pb-[100%]"
              onClick={() => history.push(`/detail-post=${post.postID}`)}
            >
              <img
                src={post.imagePost}
                className="absolute inset-0 object-cover w-full h-[110%] blur-[10px] transform"
                alt={`Post ${index + 1}`}
              />
              <div
                className={`absolute inset-0 flex items-start my-auto pt-4 pl-[12%] ${isDesktop ? (index % 2 === 0 ? "bg-[#005498]" : "bg-[#002A41]") : "bg-[#005498]"} bg-opacity-[.89]`}
              >
                <div className="flex flex-wrap flex-col gap-3 my-auto items-start">
                  <div className="flex flex-wrap flex-col gap-0 my-auto items-start">
                    {post.contentPost?.split("\n").map((sentence) => (
                      <span
                        key={sentence}
                        className="text-xl text-left  text-white leading-[30px]"
                      >
                        {sentence}
                      </span>
                    ))}
                    <span className="text-left text-xl text-white leading-[30px]">
                      Đi du lịch
                    </span>
                    <span
                      className={`text-left flex flex-wrap items-end gap-[7px]`}
                    >
                      <span className={`text-xl text-white leading-[30px]`}>
                        Đặt
                      </span>
                      <span className="transform -translate-y-[7px]">
                        <svg
                          width={108}
                          height={25}
                          viewBox="0 0 259 57"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.64501 32.6459H0.00310478L0 28.3427H3.80646L3.80025 20.2858L8.98833 20.2796L8.99454 28.3334L15.7288 28.3272V32.6335L8.99764 32.6397L9.01006 45.0619C9.01627 50.635 12.9345 51.3491 14.9774 51.3491C15.7505 51.3491 16.2473 51.2373 16.2473 51.2373V55.9814C16.2473 55.9814 15.4773 56.0932 14.372 56.0963C10.786 56.0994 3.66364 55.0034 3.65743 45.6767L3.64501 32.649V32.6459Z"
                            fill="white"
                          />
                          <path
                            d="M21.1403 27.9385L26.3253 27.9323V32.7881C26.3315 33.9493 26.2228 34.8839 26.2228 34.8839H26.3315C27.5983 30.8042 30.8521 27.5938 35.1553 27.5907C35.8725 27.5907 36.5369 27.7025 36.5369 27.7025L36.5431 32.9993C36.5431 32.9993 35.8259 32.8347 34.9969 32.8347C31.5755 32.8378 28.4303 35.2689 27.1667 39.4106C26.673 41.0127 26.5085 42.7203 26.5085 44.4311L26.5178 55.8628L21.1652 55.8691L21.1403 27.9385Z"
                            fill="white"
                          />
                          <path
                            d="M64.9669 28.1182L70.6518 28.112L77.4605 46.9828C78.0101 48.5259 78.3454 50.7893 78.3982 50.7893H78.51C78.5627 50.7893 78.9477 48.5259 79.5004 46.9828L86.2719 28.0996L91.9009 28.0934L81.6054 56.0302L75.4269 56.0364L64.9669 28.1182Z"
                            fill="white"
                          />
                          <path
                            d="M107.409 27.4176C115.305 27.4114 119.503 33.2608 119.509 40.4856C119.509 41.2028 119.347 42.8049 119.347 42.8049L98.98 42.8235C99.2626 48.9492 103.625 52.0354 108.589 52.0292C113.392 52.0261 116.87 48.766 116.87 48.766L119.08 52.6843C119.08 52.6843 114.945 56.6615 108.266 56.6677C99.4923 56.677 93.469 50.3308 93.4597 42.0535C93.4504 33.1676 99.4644 27.4238 107.409 27.4145V27.4176ZM114.048 38.7811C113.877 33.9811 110.893 31.6649 107.307 31.668C103.224 31.6711 99.9115 34.2139 99.1446 38.7966L114.051 38.7842L114.048 38.7811Z"
                            fill="white"
                          />
                          <path
                            d="M124.57 17.0263L129.923 17.0201L129.951 47.4313C129.951 50.8 131.279 51.4055 132.934 51.4023C133.431 51.4023 133.872 51.3465 133.872 51.3465V56.0937C133.872 56.0937 133.046 56.2054 132.112 56.2085C129.075 56.2085 124.601 55.3858 124.595 48.3783L124.567 17.0263H124.57Z"
                            fill="white"
                          />
                          <path
                            d="M151.46 27.3775C159.793 27.3713 166.537 33.4908 166.543 41.9358C166.552 50.4336 159.821 56.6245 151.488 56.6307C143.155 56.6369 136.414 50.4615 136.405 41.9638C136.399 33.5188 143.127 27.3837 151.46 27.3775ZM151.482 51.9922C156.779 51.986 161.079 47.7914 161.073 41.942C161.066 36.1454 156.76 32.0098 151.463 32.0129C146.219 32.0191 141.863 36.1609 141.866 41.9575C141.873 47.8069 146.235 51.9953 151.479 51.9891L151.482 51.9922Z"
                            fill="white"
                          />
                          <path
                            d="M171.765 16.9827L177.115 16.9765L177.133 38.2815C177.133 38.2815 176.022 38.2815 178.667 38.2815C181.319 38.2815 183.265 35.2978 183.265 35.2978L188.664 28.0078L194.734 28.0016L185.473 40.3182V40.4269L187.677 43.7335L195.805 55.926L189.571 55.9322L182.505 44.8947C182.505 44.8947 181.253 42.8052 179.555 42.8083C177.86 42.8083 177.14 42.8083 177.14 42.8083L177.152 55.9415L171.802 55.9477L171.768 16.9796L171.765 16.9827Z"
                            fill="white"
                          />
                          <path
                            d="M214.282 38.4705H215.5V37.9706C215.496 33.3911 212.901 31.8449 209.368 31.8511C205.064 31.8542 201.584 34.5616 201.584 34.5616L199.376 30.6496C199.376 30.6496 203.4 27.3337 209.802 27.3275C216.866 27.3213 220.843 31.1805 220.849 38.2439L220.865 55.9069H215.9V53.2617C215.9 51.9949 216.009 52.1936 216.009 52.1936H215.9C215.9 52.1936 213.643 56.5776 206.961 56.5838C202.161 56.5869 197.467 53.6653 197.461 48.0891C197.451 38.8741 209.594 38.4767 214.286 38.4736L214.282 38.4705ZM208.116 52.2774C212.64 52.2743 215.562 47.524 215.559 43.3792V42.4974H214.18C210.15 42.5005 202.866 42.783 202.869 47.8035C202.869 50.0668 204.639 52.2774 208.119 52.2743L208.116 52.2774Z"
                            fill="white"
                          />
                          <path
                            d="M55.3152 38.6163H56.5292V38.1164C56.5261 33.5369 53.9274 31.9907 50.3942 31.9969C46.0909 32 42.6198 34.7074 42.6198 34.7074L40.4092 30.7954C40.4092 30.7954 44.433 27.4795 50.835 27.4733C57.8984 27.467 61.8787 31.3263 61.8849 38.3897L61.9005 56.0527H56.9328V53.4075C56.9328 52.1407 57.0446 52.3394 57.0446 52.3394H56.9328C56.9328 52.3394 54.6725 56.7234 47.9942 56.7296C43.1942 56.7327 38.5029 53.8111 38.4966 48.2349C38.4873 39.0199 50.6301 38.6225 55.3214 38.6194L55.3152 38.6163ZM49.146 52.4201C53.6697 52.417 56.5944 47.6667 56.5913 43.5218V42.6401H55.2097C51.1828 42.6432 43.899 42.9257 43.9021 47.9462C43.9021 50.2095 45.6687 52.4201 49.1491 52.417L49.146 52.4201Z"
                            fill="white"
                          />
                          <path
                            d="M217.62 22.8107C217.62 22.8107 218.166 22.82 218.759 22.5561C219.423 22.2735 220.2 21.7085 221.438 21.7085C222.839 21.724 224.475 22.5437 225.456 23.0528C226.22 23.4316 227.334 22.8697 227.334 22.8697C227.334 22.8697 228.85 21.0037 228.44 18.6906C227.856 15.4586 225.447 11.1088 226.915 7.82702C229.523 2.02419 237.605 -0.00012207 237.605 -0.00012207C237.605 -0.00012207 237.279 4.58563 237.161 8.39209C237.102 10.3202 237.214 12.8412 237.214 12.8412C236.993 13.9776 240.632 29.2003 244.184 32.333C247.068 35.8321 252.514 38.1545 252.514 38.1545C252.514 38.1545 251.216 37.7602 251.108 37.9185C251.052 37.9837 251.102 38.465 251.505 38.6823C250.217 37.993 246.066 35.8694 240.884 34.3356C235.885 32.8733 230.84 32.6062 226.981 30.9297C223.115 29.2438 223.416 25.9155 218.812 24.1457C214.859 22.6244 208.277 21.3607 208.277 21.3607L217.616 22.8107H217.62Z"
                            fill="white"
                          />
                          <path
                            d="M242.502 27.2843C242.703 26.1728 245.721 21.1679 249.428 16.4145C253.586 11.0867 258.466 6.0011 258.466 6.0011C258.466 6.0011 252.99 6.62516 247.37 8.43214C245.206 9.13071 242.995 10.0559 241.191 10.8756C241.191 10.8756 240.738 21.3635 242.427 28.1102C242.427 27.837 242.446 27.5607 242.502 27.2843Z"
                            fill="white"
                          />
                          <path
                            d="M241.189 10.8795C238.845 11.9445 237.187 12.8418 237.187 12.8418C236.963 13.975 240.605 29.1946 244.157 32.3336C243.095 31.0606 242.4 29.6386 242.425 28.1142C240.732 21.3675 241.189 10.8795 241.189 10.8795Z"
                            fill="#D1D1D1"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>

                  <div className="flex flex-wrap gap-2 items-center text-white">
                    <Avatar
                      src={`${process.env.REACT_APP_CDN_URL}${post.userAvatar}`}
                      round
                      size="24"
                    />
                    <span className="font-semibold">{post.userName}</span>
                  </div>

                  <div className="flex flex-wrap gap-2 items-center text-white pl-0.5">
                    <IoMdHeart size={20} color="#FF6000" />
                    <span className="font-semibold">{post.voteCount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div
        className="flex flex-wrap justify-center items-center py-2 text-center underline text-[#242424] md:text-white md:mt-12 text-xs md:text-base bg-white md:bg-[#0A9AF2] cursor-pointer"
        // onClick={() => history.push("/post-list")}
      >
        {/* Xem thêm bài dự thi */}
      </div>
    </div>
  );
});

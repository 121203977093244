import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import {
  CarouselPromotion,
  Footer,
  FormReview,
  Loading,
  NavBar,
} from "../../views";
import { FaCheck } from "react-icons/fa";
import { BsArrowUpShort } from "react-icons/bs";
import { arrayTemp, arrayTextPositon } from "../../stores/form-store.type";
import formStore from "../../stores/form.store";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { PopupModal } from "../../views/popup-modal/popup-modal.view";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import authenticationStore from "../../stores/authentication.store";
import { useHistory } from "react-router-dom";
import "./form-star-trek.css";
import endForm from "../../assets/form/end_form.png";

interface IFormStarTrekMobileProps {}

export const FormStarTrekMobile: React.FC<IFormStarTrekMobileProps> = observer(
  (props) => {
    const inputFileRef = useRef<HTMLInputElement>(null);

    const history = useHistory();

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!formStore.postIDSuccess) return;
      const parsedHTML = document
        .createRange()
        .createContextualFragment(
          `<div class="zalo-share-button" data-href="https://traveloka.link/e/${formStore.postIDSuccess}" data-oaid="4498034832828454541" data-layout="1" data-color="blue" data-share-type="3" data-customize="true"></div><script src="https://sp.zalo.me/plugins/sdk.js"></script>`
        );
      divRef.current?.appendChild(parsedHTML);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStore.postIDSuccess]);

    return (
      <div className="relative bg-white">
        {formStore.loadingSubmit && <Loading topPercent={15} />}
        <NavBar />
        {formStore.stepNumber === 1 && (
          <>
            <div className="flex flex-wrap flex-col w-full mt-16 md:mt-4 pt-6 gap-3 ">
              <div className="flex flex-wrap flex-col items-center w-full gap-0">
                <h3 className="text-[#079AF2] uppercase text-center">Bước 1</h3>
                <p className="text-[#079AF2] text-center">
                  Chọn ảnh nền billboard của bạn ngay!
                </p>
              </div>

              <FormReview />

              <div className="flex flex-col gap-0 flex-wrap w-full mt-2">
                {formStore.errorUploadFile && (
                  <p className="px-3 text-center w-full text-red-300 font-bold text-xs my-2">
                    {formStore.errorUploadFile}
                  </p>
                )}
                <p className="px-3 text-center w-full text-[#464646] text-xs">
                  Tải ảnh của bạn hoặc chọn ảnh “chị đẹp”
                </p>
                <p className="px-3 text-center w-full text-[#464646] text-xs mb-2">
                  Ninh Dương Lan Ngọc sẵn có từ BTC.
                </p>
                <div className="w-full px-2 flex flex-wrap justify-start gap-1.5 mt-2">
                  {arrayTemp.map((item, index) => {
                    // upload image
                    if (index === 0) {
                      return (
                        <div
                          key={index}
                          className={`w-[calc(33%-4px)] flex relative flex-wrap mb-1 relative ${formStore.localUploadImageUrl && "border-[3px] border-[#00B5F0]"}`}
                          onClick={() => inputFileRef.current?.click()}
                        >
                          <img
                            src={item.src}
                            alt={`temp${index}`}
                            className="w-full"
                          />
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00B5F0] p-1 rounded-[99px]">
                            {formStore.localUploadImageUrl ? (
                              <FaCheck className="text-white" />
                            ) : (
                              <BsArrowUpShort
                                className="text-white"
                                size={20}
                              />
                            )}
                          </div>
                          <p className="absolute text-[10px] text-[#909090] bottom-1 left-1">
                            16:9
                          </p>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        className={`w-[calc(33%-4px)] flex flex-wrap mb-1 relative ${formStore.tempSrc === item.src ? "border-[3px] border-[#00B5F0]" : ""}`}
                        onClick={() => formStore.updateTemp(item)}
                      >
                        <img
                          src={process.env.REACT_APP_CDN_URL + item.src}
                          alt={`temp${index}`}
                          className="w-full"
                        />
                        {formStore.tempSrc === item.src && (
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00B5F0] p-1.5 rounded-[99px]">
                            <FaCheck className="text-white" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {/* Hidden input file element */}
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                    onChange={formStore.handleChangeFileInput}
                    ref={inputFileRef}
                  />
                </div>
              </div>

              <p className="w-full px-5 my-3 text-xs text-[#464646] text-center">
                Hình lên billboard tỉ lệ 16:9, bạn nhớ crop hình trước khi
                upload nhé.
              </p>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center mt-6 mb-8">
              <button
                onClick={() => formStore.updateStep(2)}
                className="bg-[#079AF2] rounded-[99px] px-8 flex flex-wrap justify-center items-center text-white text-sm  py-2 gap-1 "
              >
                <span>Đến bước 2</span>
                <span>
                  <GrFormNext />
                </span>
              </button>
            </div>
          </>
        )}
        {formStore.stepNumber === 2 && (
          <>
            <div className="flex flex-wrap flex-col w-full mt-16 md:mt-4 pt-6 gap-4">
              <div className="flex flex-wrap flex-col items-center w-full gap-0">
                <h3 className="text-[#079AF2] uppercase text-center">Bước 2</h3>
                <p className="text-[#079AF2] text-center">
                  Gieo những cặp vần cực chất!
                </p>
              </div>

              <FormReview />

              <div className="flex flex-col gap-2 flex-wrap w-full px-4">
                <p className="px-3 text-center text-[#464646] text-xs my-2">
                  Hoan nghênh những ý tưởng dù “mặn mòi” hay “vô tri” của các
                  bạn! Xuống tay nhả chữ ngay!
                </p>
                <textarea
                  className="min-h-[80px] rounded-[12px] border-[0.5px] border-black text-[#464646] p-2 text-sm"
                  value={formStore.quote}
                  onChange={(e: any) => formStore.updateQuote(e)}
                />
                <label className="text-center text-xs text-[#BDBDBD]">{`${formStore.quote.length || 0}/90`}</label>
                {formStore.errorQuote && (
                  <label className="text-center text-xs text-red-500">
                    {formStore.errorQuote}
                  </label>
                )}
              </div>

              <p className="text-center text-xs text-[#464646] px-4">
                <span className="text-center text-xs">
                  Tips: Châm ngôn dù hài hước, sáng tạo hay truyền cảm hứng đều
                  được BTC ưu ái. Nhưng phải hợp lệ nhé, xem thể lệ{" "}
                </span>
                <span
                  className="text-center underline text-xs"
                  onClick={() => popUpStore.openModal(EPopUp.RULE)}
                >
                  tại đây
                </span>
              </p>

              <div className="flex flex-col flex-wrap gap-2 justify-center items-center w-full px-4 mt-2">
                <p className="px-3 text-center text-[#079AF2] font-bold text-sm">
                  Vị trí chữ trên hình
                </p>
                <div className="flex flex-wrap w-full justify-between items-center">
                  {arrayTextPositon.map((item, index) => {
                    return (
                      <button
                        key={item.positionNumber}
                        onClick={() =>
                          formStore.updatePosition(item.positionNumber)
                        }
                        className={`${formStore.position === item.positionNumber ? "bg-[#079AF2] text-white" : "bg-[#D1D1D1] text-[#005498]"} rounded-[99px] text-center font-semibold  text-sm py-3 mb-2 w-[calc(48%-2px)]`}
                      >
                        {item.des}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-col flex-wrap gap-2 justify-center items-center w-full px-4 mt-2">
                <p className="px-3 text-center text-[#464646] text-sm">
                  Chọn màu chữ bạn ưng
                </p>
                <div className="flex flex-wrap w-full justify-center items-center gap-2">
                  <button
                    onClick={() => formStore.updateTextColor("white")}
                    className={`border-[1px] border-[#079AF2] relative bg-white rounded-[50%] h-10 w-10 text-center font-semibold  text-sm mb-2`}
                  >
                    {formStore.textColor === "white" && (
                      <FaCheck
                        size={12}
                        color="#005498"
                        className="absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                      />
                    )}
                  </button>
                  <button
                    onClick={() => formStore.updateTextColor("#005498")}
                    className={`bg-[#005498] rounded-[50%] h-10 w-10 text-center font-semibold  text-sm mb-2 relative`}
                  >
                    {formStore.textColor !== "white" && (
                      <FaCheck
                        size={12}
                        color="white"
                        className="absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-wrap justify-between items-center mt-6 mb-8 px-4">
              <button
                onClick={() => formStore.updateStep(1)}
                className="bg-[#079AF2] rounded-[99px] w-[48%] flex flex-wrap justify-center items-center text-white text-sm  py-2 gap-1 "
              >
                <span>
                  <GrFormPrevious />
                </span>
                <span>Về bước 1</span>
              </button>
              <button
                disabled={
                  !!formStore.errorQuote ||
                  formStore.quote ===
                    "Dòng một của bạn ở đây\nDòng hai ở vị trí này"
                }
                onClick={() => formStore.updateStep(3)}
                className={`bg-[#079AF2] rounded-[99px] w-[48%] flex flex-wrap justify-center items-center text-white text-sm  py-2 gap-1 ${
                  formStore.errorQuote ||
                  formStore.quote ===
                    "Dòng một của bạn ở đây\nDòng hai ở vị trí này"
                    ? "opacity-50"
                    : ""
                }`}
              >
                <span>Nộp bài</span>
                <span>
                  <GrFormNext />
                </span>
              </button>
            </div>
          </>
        )}
        {formStore.stepNumber === 3 && (
          <>
            <div className="flex flex-wrap flex-col w-full mt-16 md:mt-4 pt-6 gap-4">
              <div className="flex flex-wrap flex-col items-center w-full gap-0">
                <h3 className="text-[#079AF2] uppercase text-center">
                  SẴN SÀNG NỘP BÀI THÔI!
                </h3>
                <p className="text-[#079AF2] text-center">
                  Chắc là được nhiều tim lắm đây
                </p>
              </div>

              <FormReview />
            </div>

            <div className="flex flex-wrap justify-center items-center gap-1 mt-4">
              <input
                type="checkbox"
                defaultChecked={formStore.isAcceptRule}
                onChange={(e) => formStore.updateIsAcceptRule(e)}
              />
              <p className="text-center text-[#464646] text-xs">
                Tôi xác nhận đã đọc, và đồng ý với{" "}
                <span
                  className="text-[#464646] text-xs underline"
                  onClick={() => popUpStore.openModal(EPopUp.RULE)}
                >
                  thể lệ cuộc thi
                </span>
              </p>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center mt-6">
              <button
                disabled={!formStore.isAcceptRule}
                onClick={() => formStore.onSubmitPost()}
                className={`bg-[#079AF2] rounded-[99px] w-10/12 font-bold flex flex-wrap justify-center items-center text-white text-sm  py-3 ${!formStore.isAcceptRule && "opacity-50"}`}
              >
                <span>Nộp bài dự thi</span>
              </button>
            </div>

            <div className="flex flex-col flex-wrap justify-center items-center gap-2 mt-4 mb-8">
              <p
                className="text-center text-[#005498] underline text-sm cursor-pointer"
                onClick={() => popUpStore.openModal(EPopUp.CHANGE_PROFILE)}
              >
                Chỉnh sửa tên và avatar của bạn
              </p>
              <p
                className="text-center text-[#005498] underline text-sm cursor-pointer"
                onClick={() => formStore.updateStep(2)}
              >
                Quay về chỉnh sửa bài thi
              </p>
            </div>
          </>
        )}
        {/* Success */}
        {formStore.stepNumber === 4 && (
          <>
            <div className="flex flex-wrap flex-col w-full mt-12 pt-8 gap-4">
              <div className="flex flex-wrap flex-col items-center w-full gap-0">
                <h3 className="text-[#0075DC] uppercase text-center font-semibold">
                  BÀI THI ĐÃ HOÀN THÀNH
                </h3>
                <h3 className="text-[#0075DC] uppercase text-center font-semibold">
                  CHIA SẺ NGAY
                </h3>
              </div>

              <div className="flex flex-col flex-wrap w-full gap-1 relative">
                <div className="flex flex-col flew-wrap gap-0">
                  <div className="w-full">
                    <img
                      className="w-full"
                      src={`https:${formStore.urlImageSuccess}`}
                      alt="Startrek"
                    />
                  </div>
                </div>
                <div className="text-center text-xs text-[#757575] italic">
                  *Nhấn giữ vào ảnh để tải ảnh về
                </div>
              </div>

              <div className=" flex flex-wrap justify-between items-center w-full px-4">
                <div
                  className="relative rounded-[99px] h-10 flex justify-center items-center w-[48%] text-center bg-[#2196F3] text-white font-bold text-xs"
                  ref={divRef}
                >
                  Chia sẻ Zalo
                </div>

                <div
                  className="rounded-[99px] h-10 flex justify-center items-center w-[48%] text-center bg-[#0866FF] text-white font-bold "
                  data-href="https://startrek.traveloka.link/"
                  data-layout="button_count"
                  data-size="small"
                >
                  <a
                    className="w-full fb-xfbml-parse-ignore text-xs"
                    rel="noreferrer"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftraveloka.link/e/${formStore.postIDSuccess}&src=sdkpreparse&hashtag=%23DiDuLichDatTraveloka`}
                  >
                    Chia sẻ Facebook
                  </a>
                </div>
              </div>

              <p className="text-center pt-1 px-8 text-xs text-[#464646]">
                Sao chép link để chia sẻ bạn bè
              </p>

              <div className="relative flex flex-wrap items-center w-full px-4">
                <input
                  className="py-2.5 px-3 bg-gray-300 text-[#005498] rounded-[99px] w-10/12 text-xs"
                  disabled
                  defaultValue={`https://traveloka.link/e/${formStore.postIDSuccess}`}
                />
                <button
                  className="rounded-[99px] py-2.5 px-4 bg-sky-500 text-white font-bold absolute z-10 right-4 top-0 text-xs"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://traveloka.link/e/${formStore.postIDSuccess}` ||
                        ""
                    );

                    withReactContent(Swal).fire({
                      position: "center",
                      title:
                        '<h5 style="font-size: 10px; color: #005498">Copied Clipboard</h5>',
                      width: 150,
                      color: "var(--secondary)",
                      showConfirmButton: false,
                      timer: 800,
                    });
                  }}
                >
                  Copy Link
                </button>
              </div>

              <div className=" flex flex-wrap flex-col w-full px-8 gap-3 mt-3">
                <p className="text-center pt-1 px-8 text-xs text-[#464646]r">
                  Chia sẻ ngay cho bạn bè, người thân bình chọn liền tay, giật
                  ngay voucher khủng, chiếm sóng trên billboard/LED
                </p>
              </div>

              <div className="flex flex-col flex-wrap w-full gap-1 relative bg-white pt-4">
                <div className="flex flex-col flew-wrap gap-0">
                  <div className="w-full">
                    <img className="w-full" src={endForm} alt="Startrek" />
                  </div>
                </div>
                <div className="absolute bottom-6 px-20 w-full flex flex-wrap justify-center flex-col gap-3">
                  <button
                    className="bg-[#FF7902] px-8 py-1 rounded-[99px] text-white text-sm"
                    onClick={() => {
                      if (authenticationStore.accessToken) {
                        history.push("/form-startrek");
                      } else {
                        popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
                      }
                    }}
                  >
                    Nhả chữ ngay
                  </button>
                  <div
                    className="text-xs text-white underline text-center"
                    onClick={() => popUpStore.openModal(EPopUp.RULE)}
                  >
                    Chi tiết thể lệ và giải thưởng
                  </div>
                </div>
              </div>
            </div>

            <CarouselPromotion />
          </>
        )}
        <Footer />
        <PopupModal />
      </div>
    );
  }
);

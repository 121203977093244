import React from "react";
import "./loading.style.css";
import { ILoadingProps } from "./loading.type";

const prefixClassName = "loading";

export const Loading: React.FC<ILoadingProps> = (props) => {
  const {
    heightOfWrapper = "100%",
    widthOfWrapper = "100%",
    isOverlay = true,
    sizeOfLines = 80,
    topPercent = 50,
  } = props;

  const customLoaderStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#00B5F0 #00B5F0 transparent",
    display: "inline-block",
    animation: "0.75s linear 0s infinite normal both clip_custom",
  };

  return (
    <div
      className={isOverlay ? `${prefixClassName}__overlay` : prefixClassName}
      style={{
        height: heightOfWrapper,
        width: widthOfWrapper,
        top: `${topPercent}%`,
        zIndex: 2000,
      }}
    >
      <div className="relative">
        <div style={customLoaderStyle}></div>
        <svg
          className="absolute top-[48%] left-[49%] transform -translate-y-1/2 -translate-x-1/2"
          width={32}
          height={24}
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.57222 8.72489C3.57222 8.72489 3.78105 8.72837 4.00766 8.62753C4.26189 8.51896 4.55863 8.30355 5.03309 8.30317C5.56919 8.30896 6.19511 8.62309 6.57028 8.81782C6.86238 8.96232 7.28874 8.74788 7.28874 8.74788C7.28874 8.74788 7.8683 8.03386 7.71144 7.14926C7.48753 5.91364 6.56603 4.24934 7.12878 2.99401C8.12582 0.774486 11.2174 0 11.2174 0C11.2174 0 11.0932 1.75452 11.048 3.20941C11.0259 3.94681 11.0684 4.911 11.0684 4.911C10.9844 5.34528 12.3754 11.1685 13.7344 12.3667C14.8381 13.7047 16.9206 14.5939 16.9206 14.5939C16.9206 14.5939 16.4245 14.4434 16.3832 14.5037C16.3618 14.529 16.3809 14.7127 16.5356 14.7956C16.0434 14.5317 14.4556 13.7203 12.4729 13.133C10.5607 12.5736 8.6312 12.4721 7.15428 11.8304C5.67621 11.1861 5.79077 9.91222 4.02949 9.23568C2.51742 8.65419 0 8.17064 0 8.17064L3.57222 8.72489Z"
            fill="#1BA0E2"
          />
          <path
            d="M13.0895 10.4365C13.1672 10.0111 14.3209 8.09705 15.7387 6.27839C17.329 4.24066 19.1962 2.29565 19.1962 2.29565C19.1962 2.29565 17.1009 2.53424 14.9519 3.22585C14.1241 3.49341 13.2787 3.84656 12.588 4.16049C12.588 4.16049 12.4142 8.17162 13.061 10.7524C13.061 10.6479 13.0683 10.5422 13.0895 10.4364V10.4365Z"
            fill="#1494C6"
          />
          <path
            d="M12.5878 4.16064C11.6909 4.56808 11.0568 4.91098 11.0568 4.91098C10.9716 5.34391 12.3637 11.166 13.723 12.3666C13.3173 11.88 13.0513 11.3352 13.0607 10.7526C12.414 8.17178 12.5878 4.16064 12.5878 4.16064Z"
            fill="#0D7FCC"
          />
        </svg>
      </div>
    </div>
  );
};

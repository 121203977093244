import { API } from "../api";
import { IVoteWithAutheResponse } from "./vote-with-authen.api";

export interface ISignInAndVoteResponse {
  thirdPartyId?: string;
  method?: string;
  name?: string;
  avatar?: string;
  isComeback?: boolean;
  accessToken: string;
  voteFail?: string;
  postId?: string;
}

export const signinAndVoteFacebookAPI = (
  postId: string,
  facebookCode: string
) => {
  const formData = new URLSearchParams();
  formData.append("postId", postId);
  formData.append("facebookCode", facebookCode);

  return API.post<ISignInAndVoteResponse | IVoteWithAutheResponse | any>(
    "/vote/signin-and-vote-facebook",
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "clean-request": "no-clean",
      },
    }
  );
};

import React, { useState } from "react";
import { observer } from "mobx-react";
import formStore from "../../stores/form.store";
import { positionClassesMap } from "../../stores/form-store.type";
import footer from "../../assets/form/footer.png";
import authenticationStore from "../../stores/authentication.store";
import Avatar from "react-avatar";
import userStore from "../../stores/user.store";
import { MustEndQuoteDesktop } from "../../components/must-end-quote/must-end-quote-desktop.component";
import previewPCDay from "../../assets/form/preview_PC.png";
import previewPCNight from "../../assets/home/billboard_holder_night.jpg";
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa";

interface IFormReviewDesktopProps {}

export const FormReviewDesktop: React.FC<IFormReviewDesktopProps> = observer(
  (props) => {
    return (
      <div className="w-[996px] h-[586px] mx-auto relative">
        <img
          className="w-full h-full"
          src={`${process.env.REACT_APP_CDN_URL}${formStore.isDarkMode ? previewPCNight : previewPCDay}`}
          alt="preview"
        />
        <div className="absolute bottom-4 right-4 flex flex-wrap gap-2 items-center justify-center">
          <span
            onClick={() => formStore.setIsDarkMode(false)}
            className={`rounded-[99px] p-2.5 ${!formStore.isDarkMode && "bg-white"} text-black cursor-pointer border-2 border-white`}
          >
            <FaSun color="#FFFF00" />
          </span>
          <span
            onClick={() => formStore.setIsDarkMode(true)}
            className={`rounded-[99px] p-2.5 ${formStore.isDarkMode && "bg-white"} text-black cursor-pointer border-2 border-white`}
          >
            <FaMoon />
          </span>
        </div>
        <div className="absolute top-[57.5px] left-[116.5px]">
          <div className="flex flex-col flex-wrap w-full gap-0 relative">
            <div className="flex flex-col flex-wrap w-full gap-0 relative md:w-[769px]">
              <div className="flex flex-col flew-wrap gap-0">
                <div
                  className="w-full"
                  style={{
                    paddingBottom: "52.75%",
                    position: "relative",
                  }}
                >
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${formStore.localUploadImageUrl || formStore.tempSrc})`,
                    }}
                  />
                </div>

                <img
                  src={footer}
                  alt="Temp1"
                  className="w-full transform -translate-y-[2px]"
                />
              </div>
            </div>
          </div>
          <div
            className={`absolute ${positionClassesMap.get(formStore.position || 1)} max-w-[65%] flex flex-col flex-wrap`}
          >
            {formStore.quote.split("\n").map((sentence) => (
              <span
                key={sentence}
                className={`${formStore.textColor === "white" ? "text-white text-shadow-for-text-white" : `text-[${formStore.textColor}] text-shadow-for-text-blue`} text-[28px]`}
              >
                {sentence}
              </span>
            ))}

            <MustEndQuoteDesktop />

            <div className={`flex flex-wrap items-center gap-2 mt-1`}>
              {(userStore.userProfile === undefined &&
                !authenticationStore.profileLogin?.isIgnoreAvatarGen) ||
              !userStore.userProfile?.isIgnoreAvatarGen ? (
                <Avatar
                  className="font-bold"
                  src={process.env.REACT_APP_API_ENDPOINT?.replace(
                    "/api",
                    authenticationStore.profileLogin?.avatar ||
                      userStore.userProfile?.avatar ||
                      ""
                  )}
                  alt={authenticationStore.profileLogin?.name}
                  round
                  size="30"
                />
              ) : (
                <div className="w-2" />
              )}

              <h4
                className={`${formStore.textColor === "white" ? "text-white text-shadow-for-text-white" : `text-[${formStore.textColor}] text-shadow-for-text-blue`} font-bold text-base`}
              >
                {authenticationStore.profileLogin?.name ||
                  userStore.userProfile?.name}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

import React from "react";
import { observer } from "mobx-react";
import formStore from "../../stores/form.store";

export const MustEndQuote: React.FC = observer(() => {
  if (formStore.textColor === "white")
    return (
      <>
        <span className="text-white text-shadow-for-text-white text-xs">
          Đi du lịch
        </span>
        <span className="text-white text-shadow-for-text-white flex flex-wrap items-end gap-1 text-xs">
          <span className="text-white text-shadow-for-text-white text-xs">
            Đặt
          </span>
          <span className="transform -translate-y-[3.75px]">
            <svg
              width={65}
              height={14}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 233 52"
              fill="none"
            >
              <g filter="url(#filter0_d_2070_814)">
                <path
                  d="M3.27177 29.3032H0.00278685L0 25.4406H3.41668L3.41111 18.2087L8.06794 18.2031L8.07352 25.4322L14.1182 25.4267V29.292L8.0763 29.2976L8.08745 40.4478C8.09303 45.4502 11.61 46.0912 13.4438 46.0912C14.1377 46.0912 14.5836 45.9909 14.5836 45.9909V50.2492C14.5836 50.2492 13.8925 50.3495 12.9003 50.3523C9.68153 50.3551 3.28849 49.3713 3.28291 40.9996L3.27177 29.306V29.3032Z"
                  fill="white"
                />
                <path
                  d="M18.9756 25.0778L23.6296 25.0722V29.4308C23.6352 30.4731 23.5377 31.3119 23.5377 31.3119H23.6352C24.7722 27.65 27.6929 24.7684 31.5554 24.7656C32.1992 24.7656 32.7956 24.866 32.7956 24.866L32.8012 29.6203C32.8012 29.6203 32.1574 29.4726 31.4133 29.4726C28.3422 29.4754 25.5191 31.6575 24.3849 35.3752C23.9418 36.8132 23.7941 38.346 23.7941 39.8815L23.8024 50.1427L18.9979 50.1483L18.9756 25.0778Z"
                  fill="white"
                />
                <path
                  d="M58.3145 25.2391L63.4172 25.2335L69.5288 42.172C70.022 43.5571 70.323 45.5887 70.3704 45.5887H70.4707C70.5181 45.5887 70.8637 43.5571 71.3597 42.172L77.4378 25.2224L82.4904 25.2168L73.2492 50.2929L67.7034 50.2985L58.3145 25.2391Z"
                  fill="white"
                />
                <path
                  d="M96.411 24.6102C103.498 24.6046 107.266 29.8551 107.271 36.3401C107.271 36.9838 107.126 38.4219 107.126 38.4219L88.8447 38.4386C89.0983 43.937 93.0138 46.7072 97.47 46.7016C101.781 46.6988 104.903 43.7726 104.903 43.7726L106.887 47.2896C106.887 47.2896 103.175 50.8596 97.1802 50.8652C89.3045 50.8735 83.898 45.1772 83.8897 37.7474C83.8813 29.7715 89.2794 24.6158 96.411 24.6074V24.6102ZM102.369 34.8101C102.216 30.5016 99.5378 28.4226 96.319 28.4254C92.6543 28.4282 89.6807 30.7106 88.9924 34.824L102.372 34.8129L102.369 34.8101Z"
                  fill="white"
                />
                <path
                  d="M111.814 15.2829L116.619 15.2773L116.644 42.5746C116.644 45.5983 117.837 46.1418 119.322 46.139C119.768 46.139 120.164 46.0888 120.164 46.0888V50.3499C120.164 50.3499 119.422 50.4502 118.584 50.453C115.858 50.453 111.842 49.7145 111.837 43.4246L111.812 15.2829H111.814Z"
                  fill="white"
                />
                <path
                  d="M135.951 24.5742C143.431 24.5686 149.484 30.0615 149.489 37.6418C149.498 45.2694 143.456 50.8264 135.976 50.832C128.496 50.8375 122.446 45.2945 122.438 37.6669C122.432 30.0866 128.471 24.5798 135.951 24.5742ZM135.97 46.6684C140.725 46.6628 144.585 42.8978 144.579 37.6474C144.573 32.4443 140.708 28.7322 135.954 28.735C131.247 28.7406 127.337 32.4582 127.34 37.6613C127.345 42.9117 131.261 46.6712 135.968 46.6656L135.97 46.6684Z"
                  fill="white"
                />
                <path
                  d="M154.177 15.2439L158.979 15.2383L158.995 34.3617C158.995 34.3617 157.998 34.3617 160.372 34.3617C162.752 34.3617 164.499 31.6835 164.499 31.6835L169.346 25.14L174.794 25.1344L166.481 36.1898V36.2874L168.459 39.2554L175.755 50.1994L170.159 50.2049L163.816 40.2977C163.816 40.2977 162.693 38.4221 161.169 38.4249C159.647 38.4249 159.001 38.4249 159.001 38.4249L159.012 50.2133L154.21 50.2189L154.18 15.2411L154.177 15.2439Z"
                  fill="white"
                />
                <path
                  d="M192.34 34.5313H193.433V34.0826C193.43 29.972 191.1 28.5842 187.929 28.5898C184.066 28.5925 180.942 31.0227 180.942 31.0227L178.961 27.5112C178.961 27.5112 182.572 24.5349 188.319 24.5293C194.659 24.5237 198.229 27.9878 198.235 34.3279L198.249 50.1823H193.792V47.8079C193.792 46.6709 193.89 46.8492 193.89 46.8492H193.792C193.792 46.8492 191.766 50.7843 185.769 50.7898C181.461 50.7926 177.247 48.1702 177.241 43.165C177.233 34.8936 188.132 34.5369 192.343 34.5341L192.34 34.5313ZM186.806 46.9245C190.866 46.9217 193.489 42.6578 193.486 38.9373V38.1459H192.248C188.631 38.1487 182.093 38.4023 182.096 42.9086C182.096 44.9402 183.684 46.9245 186.808 46.9217L186.806 46.9245Z"
                  fill="white"
                />
                <path
                  d="M49.6511 34.6622H50.7407V34.2135C50.738 30.1029 48.4054 28.715 45.2339 28.7206C41.3713 28.7234 38.2556 31.1535 38.2556 31.1535L36.2714 27.6421C36.2714 27.6421 39.8832 24.6657 45.6297 24.6602C51.9697 24.6546 55.5425 28.1187 55.5481 34.4587L55.562 50.3132H51.103V47.9388C51.103 46.8017 51.2034 46.9801 51.2034 46.9801H51.103C51.103 46.9801 49.0742 50.9151 43.0797 50.9207C38.7712 50.9235 34.5603 48.3011 34.5547 43.2959C34.5463 35.0245 45.4457 34.6678 49.6567 34.665L49.6511 34.6622ZM44.1136 47.0525C48.174 47.0498 50.7993 42.7859 50.7965 39.0654V38.2739H49.5563C45.9418 38.2767 39.4038 38.5303 39.4066 43.0367C39.4066 45.0683 40.9923 47.0525 44.1164 47.0498L44.1136 47.0525Z"
                  fill="white"
                />
                <path
                  d="M195.336 20.475C195.336 20.475 195.826 20.4834 196.359 20.2465C196.955 19.9929 197.652 19.4857 198.764 19.4857C200.021 19.4996 201.489 20.2353 202.37 20.6924C203.055 21.0324 204.056 20.528 204.056 20.528C204.056 20.528 205.416 18.8531 205.048 16.7769C204.524 13.8757 202.361 9.97136 203.68 7.02566C206.021 1.81703 213.275 0 213.275 0C213.275 0 212.982 4.11618 212.876 7.53287C212.823 9.2635 212.924 11.5264 212.924 11.5264C212.726 12.5464 215.992 26.2104 219.18 29.0223C221.769 32.1631 226.657 34.2477 226.657 34.2477C226.657 34.2477 225.492 33.8937 225.395 34.0359C225.345 34.0944 225.389 34.5263 225.752 34.7214C224.595 34.1027 220.869 32.1965 216.218 30.8198C211.731 29.5072 207.202 29.2675 203.738 27.7626C200.269 26.2494 200.539 23.2619 196.406 21.6734C192.858 20.3078 186.95 19.1736 186.95 19.1736L195.333 20.475H195.336Z"
                  fill="white"
                />
                <path
                  d="M217.67 24.4906C217.851 23.4929 220.56 19.0005 223.887 14.7338C227.619 9.95159 232 5.38672 232 5.38672C232 5.38672 227.084 5.94688 222.04 7.56883C220.097 8.19587 218.113 9.02635 216.494 9.76208C216.494 9.76208 216.087 19.1761 217.603 25.2319C217.603 24.9867 217.62 24.7386 217.67 24.4906Z"
                  fill="white"
                />
                <path
                  d="M216.492 9.76562C214.388 10.7215 212.899 11.5269 212.899 11.5269C212.699 12.5441 215.968 26.2053 219.156 29.0228C218.203 27.8802 217.579 26.6038 217.601 25.2355C216.082 19.1796 216.492 9.76562 216.492 9.76562Z"
                  fill="#D1D1D1"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_2070_814"
                  x={0}
                  y={0}
                  width={233}
                  height="51.9207"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx={1} dy={1} />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.262745 0 0 0 0 0.262745 0 0 0 0 0.262745 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2070_814"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2070_814"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </span>
        </span>
      </>
    );

  return (
    <>
      <span
        className={`text-[${formStore.textColor}] text-shadow-for-text-blue text-xs`}
      >
        Đi du lịch
      </span>
      <span
        className={`text-[${formStore.textColor}] text-shadow-for-text-blue flex flex-wrap items-end gap-1 text-xs`}
      >
        <span
          className={`text-[${formStore.textColor}] text-shadow-for-text-blue text-xs`}
        >
          Đặt
        </span>
        <span className="transform -translate-y-[3.75px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={65}
            height={14}
            viewBox="0 0 233 52"
            fill="none"
          >
            <g filter="url(#filter0_d_2070_827)">
              <path
                d="M3.27177 28.9267H0.00278685L0 25.1135H3.41668L3.41111 17.9743L8.06794 17.9688L8.07351 25.1053L14.1182 25.0998V28.9157L8.0763 28.9212L8.08745 39.9286C8.09302 44.867 11.61 45.4997 13.4438 45.4997C14.1377 45.4997 14.5836 45.4007 14.5836 45.4007V49.6045C14.5836 49.6045 13.8925 49.7035 12.9003 49.7063C9.68153 49.709 3.28849 48.7379 3.28291 40.4733L3.27177 28.9294V28.9267Z"
                fill="#005498"
              />
              <path
                d="M18.9756 24.7573L23.6296 24.7518V29.0547C23.6352 30.0836 23.5377 30.9117 23.5377 30.9117H23.6352C24.7722 27.2967 27.6929 24.452 31.5554 24.4492C32.1992 24.4492 32.7956 24.5483 32.7956 24.5483L32.8012 29.2418C32.8012 29.2418 32.1574 29.0959 31.4133 29.0959C28.3422 29.0987 25.5191 31.2529 24.3849 34.9229C23.9418 36.3425 23.7941 37.8557 23.7941 39.3716L23.8024 49.5014L18.9979 49.5069L18.9756 24.7573Z"
                fill="#005498"
              />
              <path
                d="M58.3154 24.9165L63.4182 24.911L69.5297 41.6327C70.023 43 70.324 45.0056 70.3714 45.0056H70.4717C70.5191 45.0056 70.8646 43 71.3607 41.6327L77.4388 24.9L82.4914 24.8945L73.2502 49.6496L67.7043 49.6551L58.3154 24.9165Z"
                fill="#005498"
              />
              <path
                d="M96.411 24.2957C103.498 24.2902 107.266 29.4734 107.271 35.8754C107.271 36.5109 107.126 37.9305 107.126 37.9305L88.8447 37.947C89.0983 43.3751 93.0138 46.1098 97.47 46.1043C101.781 46.1015 104.903 43.2128 104.903 43.2128L106.887 46.6848C106.887 46.6848 103.175 50.209 97.1802 50.2145C89.3045 50.2228 83.898 44.5994 83.8897 37.2647C83.8813 29.3909 89.2794 24.3012 96.411 24.293V24.2957ZM102.369 34.365C102.216 30.1117 99.5378 28.0593 96.319 28.0621C92.6543 28.0648 89.6807 30.318 88.9924 34.3788L102.372 34.3678L102.369 34.365Z"
                fill="#005498"
              />
              <path
                d="M111.814 15.0856L116.619 15.0801L116.644 42.0278C116.644 45.0128 117.837 45.5493 119.322 45.5466C119.768 45.5466 120.164 45.497 120.164 45.497V49.7036C120.164 49.7036 119.422 49.8026 118.584 49.8054C115.858 49.8054 111.842 49.0763 111.837 42.8669L111.812 15.0856H111.814Z"
                fill="#005498"
              />
              <path
                d="M135.951 24.2578C143.431 24.2523 149.484 29.6749 149.49 37.1581C149.498 44.688 143.456 50.1739 135.976 50.1794C128.496 50.1849 122.446 44.7128 122.438 37.1828C122.432 29.6996 128.471 24.2633 135.951 24.2578ZM135.971 46.0691C140.725 46.0636 144.585 42.3468 144.579 37.1636C144.574 32.0271 140.708 28.3626 135.954 28.3653C131.247 28.3708 127.337 32.0409 127.34 37.1773C127.345 42.3605 131.261 46.0719 135.968 46.0664L135.971 46.0691Z"
                fill="#005498"
              />
              <path
                d="M154.177 15.0485L158.979 15.043L158.995 33.9215C158.995 33.9215 157.998 33.9215 160.372 33.9215C162.752 33.9215 164.499 31.2776 164.499 31.2776L169.346 24.8179L174.794 24.8124L166.481 35.7263V35.8226L168.459 38.7526L175.755 49.5564L170.159 49.5619L163.816 39.7815C163.816 39.7815 162.693 37.93 161.169 37.9327C159.647 37.9327 159.001 37.9327 159.001 37.9327L159.012 49.5702L154.21 49.5757L154.18 15.0457L154.177 15.0485Z"
                fill="#005498"
              />
              <path
                d="M192.341 34.0888H193.433V33.6459C193.43 29.5879 191.1 28.2178 187.929 28.2233C184.066 28.2261 180.942 30.6251 180.942 30.6251L178.961 27.1586C178.961 27.1586 182.573 24.2204 188.319 24.2149C194.659 24.2093 198.229 27.6291 198.235 33.888L198.249 49.5394H193.793V47.1954C193.793 46.0729 193.89 46.249 193.89 46.249H193.793C193.793 46.249 191.767 50.1336 185.769 50.1391C181.461 50.1419 177.247 47.553 177.241 42.6119C177.233 34.4465 188.132 34.0943 192.343 34.0916L192.341 34.0888ZM186.806 46.3233C190.866 46.3205 193.489 42.1112 193.486 38.4384V37.6571H192.249C188.631 37.6598 182.093 37.9102 182.096 42.3588C182.096 44.3644 183.685 46.3233 186.809 46.3205L186.806 46.3233Z"
                fill="#005498"
              />
              <path
                d="M49.6513 34.2197H50.741V33.7767C50.7382 29.7187 48.4056 28.3487 45.2342 28.3542C41.3716 28.3569 38.2559 30.7559 38.2559 30.7559L36.2716 27.2895C36.2716 27.2895 39.8834 24.3512 45.6299 24.3457C51.97 24.3402 55.5427 27.7599 55.5483 34.0188L55.5622 49.6702H51.1033V47.3263C51.1033 46.2038 51.2036 46.3798 51.2036 46.3798H51.1033C51.1033 46.3798 49.0744 50.2645 43.0799 50.27C38.7714 50.2728 34.5605 47.6839 34.5549 42.7428C34.5466 34.5773 45.446 34.2252 49.6569 34.2224L49.6513 34.2197ZM44.1138 46.4514C48.1743 46.4486 50.7995 42.2393 50.7967 38.5665V37.7852H49.5566C45.942 37.7879 39.4041 38.0383 39.4068 42.4869C39.4068 44.4925 40.9926 46.4514 44.1166 46.4486L44.1138 46.4514Z"
                fill="#005498"
              />
              <path
                d="M195.337 20.2129C195.337 20.2129 195.827 20.2211 196.36 19.9873C196.956 19.7369 197.653 19.2362 198.765 19.2362C200.022 19.25 201.49 19.9763 202.371 20.4275C203.056 20.7631 204.057 20.2651 204.057 20.2651C204.057 20.2651 205.417 18.6117 205.049 16.5621C204.525 13.6981 202.362 9.84369 203.681 6.9357C206.022 1.79376 213.276 0 213.276 0C213.276 0 212.983 4.06348 212.877 7.43642C212.824 9.1449 212.925 11.3788 212.925 11.3788C212.727 12.3858 215.993 25.8748 219.181 28.6507C221.77 31.7513 226.658 33.8092 226.658 33.8092C226.658 33.8092 225.493 33.4598 225.396 33.6001C225.346 33.6578 225.39 34.0843 225.753 34.2769C224.596 33.6661 220.87 31.7843 216.219 30.4252C211.732 29.1294 207.203 28.8928 203.739 27.4072C200.27 25.9133 200.54 22.964 196.407 21.3959C192.859 20.0478 186.951 18.9281 186.951 18.9281L195.334 20.2129H195.337Z"
                fill="#1BA0E2"
              />
              <path
                d="M217.67 24.1776C217.851 23.1927 220.56 18.7578 223.887 14.5458C227.619 9.82478 232 5.31836 232 5.31836C232 5.31836 227.084 5.87134 222.04 7.47253C220.097 8.09154 218.113 8.91139 216.494 9.6377C216.494 9.6377 216.087 18.9312 217.603 24.9095C217.603 24.6674 217.62 24.4225 217.67 24.1776Z"
                fill="#1494C6"
              />
              <path
                d="M216.492 9.63867C214.388 10.5823 212.9 11.3774 212.9 11.3774C212.699 12.3816 215.968 25.8678 219.156 28.6493C218.203 27.5213 217.579 26.2613 217.601 24.9104C216.082 18.9321 216.492 9.63867 216.492 9.63867Z"
                fill="#0D7FCC"
              />
              <path
                d="M217.67 24.1776C217.851 23.1927 220.56 18.7578 223.887 14.5458C227.619 9.82478 232 5.31836 232 5.31836C232 5.31836 227.084 5.87134 222.04 7.47253C220.097 8.09154 218.113 8.91139 216.494 9.6377C216.494 9.6377 216.087 18.9312 217.603 24.9095C217.603 24.6674 217.62 24.4225 217.67 24.1776Z"
                fill="#1494C6"
              />
              <path
                d="M216.492 9.63867C214.388 10.5823 212.9 11.3774 212.9 11.3774C212.699 12.3816 215.968 25.8678 219.156 28.6493C218.203 27.5213 217.579 26.2613 217.601 24.9104C216.082 18.9321 216.492 9.63867 216.492 9.63867Z"
                fill="#0D7FCC"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2070_827"
                x={0}
                y={0}
                width={233}
                height="51.27"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx={1} dy={1} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2070_827"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2070_827"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </span>
      </span>
    </>
  );
});

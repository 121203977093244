import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  BannerHome,
  CarouselPromotion,
  FeaturePost,
  Footer,
  Loading,
  NavBar,
} from "../../views";

import { useHistory, useLocation } from "react-router-dom";
import authenticationStore from "../../stores/authentication.store";
import { PopupModal } from "../../views/popup-modal/popup-modal.view";
import voteStore from "../../stores/vote.store";
import popUpStore, { EPopUp } from "../../stores/popup.store";
import seriesBanner from "../../assets/home/NDLN_series.jpg";
import PCseriesBanner from "../../assets/home/PC_series_banner.png";

interface IHomeProps {}

export const Home: React.FC<IHomeProps> = observer((props) => {
  const { search } = useLocation();

  const history = useHistory();

  const query = new URLSearchParams(search);

  const codeQuery = query.get("code");

  const stateQuery = query.get("state");

  useEffect(() => {
    if (codeQuery && stateQuery === "zalo_jn1") {
      authenticationStore.signinZaloAction(codeQuery);
    }
  }, [codeQuery, history, stateQuery]);

  useEffect(() => {
    if (codeQuery && stateQuery === "facebook_jn1") {
      authenticationStore.signinFacebookAction(codeQuery);
    }
  }, [codeQuery, history, stateQuery]);

  useEffect(() => {
    if (codeQuery && stateQuery?.split("-")[0] === "zalo_jn2") {
      const postId = stateQuery?.split("-")[1];
      history.push(`/detail-post=${postId}`);
      voteStore.signinZaloAndVote(postId, codeQuery);
    }
  }, [codeQuery, history, stateQuery]);

  useEffect(() => {
    if (codeQuery && stateQuery?.split("-")[0] === "facebook_jn2") {
      const postId = stateQuery?.split("-")[1];
      history.push(`/detail-post=${postId}`);
      voteStore.signinFacebookAndVote(postId, codeQuery);
    }
  }, [codeQuery, history, stateQuery]);

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsDesktop(true);
    }
  }, []);

  return (
    <div className="relative">
      {authenticationStore.loadingSignin && <Loading topPercent={15} />}

      <NavBar />

      <BannerHome />

      <FeaturePost />

      <div className="w-full md:-translate-y-[102px] md:bg-gradient-to-t md:from-[#3BB7F3] md:to-[#0A9AF2] flex flex-col gap-2 md:gap-12 flex-wrap justify-center items-center py-5 px-10 pt-5 pb-10 md:pb-20 bg-white">
        <p className="text-xs text-center text-[#464646] md:flex md:flex-col md:flex-wrap md:gap-1">
          <span className="md:text-white md:text-2xl">
            Săn ngay cơ hội chiếm sóng Billboard,{" "}
          </span>
          <span className="md:text-white md:text-2xl">
            giật liền voucher xịn
          </span>
        </p>
        <button
          className="bg-[#FF7902] px-16 py-1 md:py-2 rounded-[99px] text-white text-sm md:text-base"
          onClick={() => {
            // if (authenticationStore.accessToken) {
            //   history.push("/form-startrek");
            // } else {
            //   popUpStore.openModal(EPopUp.LOGIN_JOURNEY1);
            // }
          }}
        >
          Nhả chữ ngay
        </button>
      </div>

      <div className="w-full flex flex-wrap justify-center items-center md:-translate-y-[102px]">
        <img
          src={`${process.env.REACT_APP_CDN_URL}${isDesktop ? PCseriesBanner : seriesBanner}`}
          className="w-full"
          alt="seriesBanner"
        />
      </div>

      <CarouselPromotion isDesktop={isDesktop} />

      <Footer />

      <PopupModal />
    </div>
  );
});

import React from "react";
import { observer } from "mobx-react";
import { MustEndQuote } from "../../components/must-end-quote/must-end-quote.component";
import formStore from "../../stores/form.store";
import { positionClassesMap } from "../../stores/form-store.type";
import footer from "../../assets/form/footer.png";
import authenticationStore from "../../stores/authentication.store";
import Avatar from "react-avatar";
import userStore from "../../stores/user.store";

interface IFormReviewProps {}

export const FormReview: React.FC<IFormReviewProps> = observer((props) => {
  return (
    <div className="flex flex-col flex-wrap w-full gap-0 relative">
      <div className="flex flex-col flex-wrap w-full gap-0 relative">
        <div className="flex flex-col flew-wrap gap-0">
          <div
            className="w-full"
            style={{ paddingBottom: "56.25%", position: "relative" }}
          >
            <div
              className="absolute top-0 left-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${formStore.localUploadImageUrl || formStore.tempSrc})`,
              }}
            />
          </div>

          <img
            src={footer}
            alt="Temp1"
            className="w-full transform -translate-y-[2px]"
          />
        </div>
      </div>

      <div
        className={`absolute ${positionClassesMap.get(formStore.position || 1)} max-w-[65%] flex flex-col flex-wrap gap-0.5`}
      >
        {formStore.quote.split("\n").map((sentence) => (
          <span
            key={sentence}
            className={`${formStore.textColor === "white" ? "text-white text-shadow-for-text-white" : `text-[${formStore.textColor}] text-shadow-for-text-blue`} text-xs`}
          >
            {sentence}
          </span>
        ))}

        <MustEndQuote />

        <div className={`flex flex-wrap items-center gap-2 mt-1`}>
          {(userStore.userProfile === undefined &&
            !authenticationStore.profileLogin?.isIgnoreAvatarGen) ||
          !userStore.userProfile?.isIgnoreAvatarGen ? (
            <Avatar
              className="font-bold"
              src={process.env.REACT_APP_API_ENDPOINT?.replace(
                "/api",
                authenticationStore.profileLogin?.avatar ||
                  userStore.userProfile?.avatar ||
                  ""
              )}
              alt={authenticationStore.profileLogin?.name}
              round
              size="20"
            />
          ) : (
            <div className="w-2" />
          )}

          <h4
            className={`${formStore.textColor === "white" ? "text-white text-shadow-for-text-white" : `text-[${formStore.textColor}] text-shadow-for-text-blue`} font-bold text-xs`}
          >
            {authenticationStore.profileLogin?.name ||
              userStore.userProfile?.name}
          </h4>
        </div>
      </div>
    </div>
  );
});
